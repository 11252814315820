import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import { FONT_SIZES, COLORS, FONTS } from '../../../../constants';
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const styles = theme => ({
  ...dashboardStyle,
  ...loginPageStyle,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "13px",
    textAlign: 'center',
    marginBottom: "3px",
    textDecoration: "none",
    whiteSpace: "nowrap",
  },
  textCenter: {
    textAlign: 'center'
  },
  productName: {
    fontFamily: FONTS.PRIMARY,
    fontSize: "28px",
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: "nowrap",
    color: COLORS.BLACK,
  },
  manufacName: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "18px",
    fontWeight: "400",
    textAlign: 'center',
    whiteSpace: "nowrap",
    backgroundColor: "#e5f3e5",
    borderRadius: "6px",
    color: "#4caf50",
    display: "inline-block",
    padding: "10px",
  },
  productAtt: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "17px",
    fontWeight: "300",
    textAlign: 'center',
    whiteSpace: "nowrap",
    paddingTop: "10px"
    // color: "#00bcd4",
  },
  content: {
    backgroundColor: "#F9F9F9",
    padding: "0px 0px !important"
  },
  contentfooter: {
    padding: "30px",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    fontFamily: FONTS.PRIMARY
  },
  logo: {
    width: "140px",
  },
  textLight: {
    color: COLORS.GRAY,
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
  },
  textCode: {
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.NORMAL,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: '#007ACE',
    // marginTop: '5px',
    // marginBottom: '5px',
  },
  textSample: {
    display: "inline-block !important",
    padding: "5px",
    fontFamily: FONTS.PRIMARY,
    fontSize: "16px",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#212B36",
    background: "#FCF1CD",
    marginBottom: '10px',
    fontWeight: "600",
    width: "26%"
  },
  textGenuie:{
    display: "inline-block !important",
    padding: "5px",
    fontFamily: FONTS.PRIMARY,
    fontSize: "16px",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "white",
    background: "#4FB779",
    marginBottom: '10px',
    fontWeight: "600",
    width: "26%"
  },
  imgProduct: {
    textAlign: 'center',
    backgroundColor: "#F9F9F9",
    padding: "25px 25px !important",
    marginBottom: '20px'
  },
  btStatus: {
    backgroundColor: COLORS.RED_SOFT,
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "left",
    color: COLORS.RED,
    textTransform: 'none',
    marginRight: '30px',
  },
  btTrack: {
    backgroundColor: COLORS.GREEN_SOFT,
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "left",
    textTransform: 'none',
    color: COLORS.GREEN,
    "&:hover,&:focus": {
      outline: 0,
      boxShadow: "none!important",
    },
  },
  tag: {
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.LARGE,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#5a5a5a",
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  barLight: {
    width: '30%',
    borderTop: "2px solid #b7b7b7",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  barDark: {
    width: '25%',
    marginTop: "0px",
    borderTop: "2px solid #000",
  },
  barGreen: {
    width: '26vw',
    marginTop: "0px",
    borderTop: "2px solid #5cb360",
  },
  center: {
    textAlign: 'center',
  },
  noPadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 15px !important",
    },
    padding: "0px 0px !important",
  },
  textBlock: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "600",
    fontStyle: "normal",
    letterSpacing: 0,
  },
  footerText: {

    color: "#000000",
    fontWeight: "600",
  },
  icon: {
    display: "inline", width: "40px", height: "40px"
  },
  contactstyle: {
    display: "inline", height: "40px", marginLeft: "10px", margin: "0 auto"
  },
  linebreak: {
    marginBottom: "5px",
    borderTop: "1px solid #b7b7b7"
  },
  textTx: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#007bff",
  },
  txTitle: {
    color: "#333333 !important",
    fontWeight: "400",
    margin: "10px 0px 0px"
  },
  notchedOutline: {
    borderColor: "#46494D",
    color: "#46494D",
  },
  multilineColor: {
    color: "#46494D",
  },
  timelineBadge: {
    [theme.breakpoints.down("sm")]: {
      left: "5% !important"
    },
    color: "#FFFFFF",
    width: "50px",
    height: "50px",
    lineHeight: "51px",
    fontSize: "1.4em",
    textAlign: "center",
    position: "absolute",
    top: "16px",
    left: "50%",
    marginLeft: "-24px",
    zIndex: "100",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    borderBottomRightRadius: "50%",
    borderBottomLeftRadius: "50%"
  }, timelineSimpleBadge: {
    left: "5%"
  },
  badgeIcon: {
    width: "30px",
    height: "30px"
  },
  xuatxutag:{
    color: "#212B36 !important",
    fontSize: "23px !important",
    fontWeight: "600 !important",
    lineHeight: "30px !important",
    fontFamily: "Muli"

  },
  descriptionCor:{
    fontFamily: "Muli"
  },
  centerIcon:{
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0px !important",
    cursor: "pointer"
  },
  cardIcon:{
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px !important",
    cursor: "pointer"
  },
  cardIconActive:{
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px !important",
    borderTop: "3px solid #1e90ff",
    cursor: "pointer",
    width: "100px",
    backgroundColor: "#1e90ff14",
    [theme.breakpoints.down("sm")]: {
     borderTop: "none",
     borderBottom: "3px solid #1e90ff",
     width: "100%",
    },
  },
  textIcon:{
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px"
    },
    lineHeight: "17px",
    fontFamily: "Muli",
    marginBottom: "3px",
    color: "rgb(134, 142, 150)"
  },
  textIconActive:{
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px"
    },
    lineHeight: "17px",
    fontFamily: "Muli",
    marginBottom: "3px",
    color: "#1e90ff"
  },
  toTop:{
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    padding: "10px",
    backgroundColor: "#1e90ff",
    position: "fixed",
    bottom: "85px",
    right: "10px",
    zIndex: "1000",
    opacity: "0.6"
  },
  cooDes:{
    lineHeight: "25px",
    fontFamily: "Muli",
    marginTop: "10px",
    textAlign: "justify"
  },
  textStart:{
    textAlign: "start !important"
  },
  noData:{
    fontFamily: "Muli",
    fontSize: "20px",
    fontWeight: "600",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
  },
  imgNo:{
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      marginTop: "25px"
    },
    width: "400px",
    marginTop: "25px"
  },
  headquarters:{
    margin: "0 !important"
  },
  noPaddingDis:{
    padding: "0px 0px !important"
  },
  botBar:{
    width: "919px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  },
  centerBar:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});
export default styles;