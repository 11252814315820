import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getManufacturerList } from "actions/manufacturer";
import { Container, Row, Col, Card } from "reactstrap";
import StorefrontIcon from "mdi-react/StorefrontIcon";
import { truncate } from "../../../utils";
import { Pagination } from "@material-ui/lab";
import EyeIcon from 'mdi-react/EyeIcon';

const ManufacturerList = (props) => {
  const getManufacturerList = props.getManufacturerList;
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    getManufacturerList();
  }, []);

  const manufacturerList = props.manufacturerList;
  const [listManufacturer, setList] = React.useState([]);

  React.useEffect(() => {
    if (manufacturerList) {
      setList(manufacturerList);

      const total = manufacturerList.length;
      if (total) {
        const numberPage = Math.ceil(total / 8);
        setCount(numberPage);
      }
    }
  }, [manufacturerList]);

  const handleSeeDetail = (id) => [
    props.history.push("/ho-kinh-doanh/" + id),
  ];
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  return (
    <>
      <Container style={{ minHeight: "560px" }}>
        <Row>
          <Col md="12">
            <div className="w-100 border-info pb-2">
              <div className="bg-info d-inline-block p-2 mt-3 ">
                <span className="text-white">
                  {" "}
                  <StorefrontIcon className="text-white mr-1" />
                  Danh sách hộ kinh doanh
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="mt-4 d-flex flex-wrap-content flex-item-product">
              {listManufacturer.map((item, idx) => {
                if (idx >= (page - 1) * 8 && idx < page * 8) {
                  return (
                    <div
                      className="flex-stretch-card-product position-relative"
                      key={idx}
                    >
                      <Card className="no-transition w-100 card-blur-product card-manufacturer">
                        <a onClick={() => handleSeeDetail(item.uuid)} className="p-4 d-flex flex-column align-items-center card-container">
                          <div className="ava-product">
                            <img
                              className="img-product xy-center"
                              src={
                                item.avatar && item.avatar[0]
                                  ? item.avatar[0]
                                  : "https://res.cloudinary.com/agridential/image/upload/v1612235254/images/no_image_pbl5b1.png"
                              }
                              alt="product1"
                            />
                          </div>
                          <div className="info-product-card">
                            <p className="font-weight-bold mt-2 mb-1">
                              {truncate(item.name, 20)}
                            </p>
                            <p className="company-short-description">
                              {truncate(item.description, 80)}
                            </p>
                          </div>
                        </a>
                        <div className="a-size px-3">
                          <div className="text-center mobile-row">
                            <a className="btn-eye">
                              <EyeIcon
                                onClick={() => handleSeeDetail(item.uuid)}
                                className="eye-color"
                                size={20}
                              />
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                }
              })}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <Pagination
              count={count || 1}
              page={page}
              onChange={handleChangePage}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  manufacturerList: state.manufacturerList.manufacturerList,
});
const mapDispatchToProps = {
  getManufacturerList,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManufacturerList)
);
