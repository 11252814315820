const initialState = {
  data: "",
  cooName: "",
  cooInfo: [],
  cooDes: "",
  cooImage: [],
  cooCert: [],
  cooMap: "",
  cooTx: "",
  cooDynamicDescription: "",
  productList: [],
  manufacturerInfo: "",
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MANUFACTURER":
      return {
        ...state,
      };
    case "GET_MANUFACTURER_SUCCESS":
      // console.log("manufac", action)
      return {
        ...state,
        cooName: action.cooName,
        cooInfo: action.cooInfo,
        cooDes: action.cooDes,
        cooImage: action.cooImage,
        cooCert: action.cooCert,
        cooMap: action.cooMap,
        cooTx: action.cooTx,
        cooDynamicDescription: action.cooDynamicDescription,
      };
    case "GET_MANUFACTURER_FAILURE":
      return state;
    case "GET_PRODUCTS_BY_MANUFACTURER":
      return {
        ...state,
      };
    case "GET_PRODUCTS_BY_MANUFACTURER_SUCCESS":
      return {
        ...state,
        productList: action.data,
      };
    case "GET_PRODUCTS_BY_MANUFACTURER_FAILURE":
      return {
        ...state,
        error: action.error,
      };
    case "GET_MANUFACTURER_INFO":
      return {
        ...state,
      };
    case "GET_MANUFACTURER_INFO_SUCCESS":
      return {
        ...state,
        manufacturerInfo: action.data,
      };
    case "GET_MANUFACTURER_INFO_FAILURE":
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
export default reducer;
