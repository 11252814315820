import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import News from "./components/News";
import Products from "./components/Product";
import Manufacturer from "./components/Manufacturer";
import Footer from "./components/Footer";
import Advertisements from "./components/Advertisements";
import "./css/index.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAdsList } from "actions/advertisement";
import AdsSlideShow from "./components/AdsSlideShow";
import Introduction from './components/Introduction';
import ProductSlideShow from './components/ProductSlideShow';

const Info = (props) => {
  const [adsList, setAdsList] = useState([]);
  const getAdsList = props.getAdsList;
  useEffect(() => {
    getAdsList();
  }, [getAdsList]);

  useEffect(() => {
    const temp = props.adsList;
    setAdsList(temp);
  }, [props.adsList]);

  return (
    <>
      <Advertisements />
      <Header />
      {adsList &&
      adsList.length &&
      adsList[1] &&
      adsList[1].amongPagePosition === "above-content" ? (
        <AdsSlideShow show={adsList[1].show} data={adsList[1].images} />
      ) : null}
      <News />
      {adsList &&
      adsList.length &&
      adsList[1] &&
      adsList[1].amongPagePosition === "below-news" ? (
        <AdsSlideShow show={adsList[1].show} data={adsList[1].images} />
      ) : null}
      <Introduction />
      <Products />
      {adsList &&
      adsList.length &&
      adsList[1] &&
      adsList[1].amongPagePosition === "below-product" ? (
        <AdsSlideShow show={adsList[1].show} data={adsList[1].images} />
      ) : null}
      <Manufacturer />
      {adsList &&
      adsList.length &&
      adsList[1] &&
      adsList[1].amongPagePosition === "below-content" ? (
        <AdsSlideShow show={adsList[1].show} data={adsList[1].images} />
      ) : null}
      <ProductSlideShow />
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  adsList: state.advertisement.adsList,
});
const mapDispatchToProps = {
  getAdsList,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Info));
