import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/index.css";
import Advertisements from "../components/Advertisements"
// import PropTypes from 'prop-types';
import IntroDetail from "../components/IntroDetail";

function introDetailPage(props) {
  return (
    <>
      <Advertisements />
      <Header />
      <IntroDetail />
      <Footer />
    </>
  );
}

export default introDetailPage;