export const CLEAN_STORE = "CLEAN_STORE"

export const GET_OVERVIEW = "GET_OVERVIEW"
export const GET_OVERVIEW_SUCCESS = "GET_OVERVIEW_SUCCESS"
export const GET_OVERVIEW_FAILURE = "GET_OVERVIEW_FAILURE"

export const GET_DIARY = "GET_DIARY"
export const GET_DIARY_SUCCESS = "GET_DIARY_SUCCESS"
export const GET_DIARY_FAILURE = "GET_DIARY_FAILURE"

export const GET_DISTRIBUTOR = "GET_DISTRIBUTOR"
export const GET_DISTRIBUTOR_SUCCESS = "GET_DISTRIBUTOR_SUCCESS"
export const GET_DISTRIBUTOR_FAILURE = "GET_DISTRIBUTOR_FAILURE"

export const GET_MANUFACTURER_LIST = "GET_MANUFACTURER_LIST"
export const GET_MANUFACTURER_LIST_SUCCESS = "GET_MANUFACTURER_LIST_SUCCESS"
export const GET_MANUFACTURER_LIST_FAILURE = "GET_MANUFACTURER_LIST_FAILURE"

export const GET_CERTIFICATE_SUCCESS = "GET_CERTIFICATE_SUCCESS"

export const GET_MAP_LOCATION = "GET_MAP_LOCATION"
export const GET_MAP_LOCATION_SUCCESS = "GET_MAP_LOCATION_SUCCESS"
export const GET_MAP_LOCATION_FAILURE = "GET_MAP_LOCATION_FAILURE"

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST'
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS'
export const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE'

export const GET_NEWS_LIST = 'GET_NEWS_LIST'
export const GET_NEWS_LIST_SUCCESS = 'GET_NEWS_LIST_SUCCESS'
export const GET_NEWS_LIST_FAILURE = 'GET_NEWS_LIST_FAILURE'

export const GET_OPINFO_LIST = 'GET_OPINFO_LIST'
export const GET_OPINFO_LIST_SUCCESS = 'GET_OPINFO_LIST_SUCCESS'
export const GET_OPINFO_LIST_FAILURE = 'GET_OPINFO_LIST_FAILURE'

export const GET_OPINFO_BY_ID = 'GET_OPINFO_BY_ID'
export const GET_OPINFO_BY_ID_SUCCESS = 'GET_OPINFO_BY_ID_SUCCESS'
export const GET_OPINFO_BY_ID_FAILURE = 'GET_OPINFO_BY_ID_FAILURE'

export const GET_NEWS_BY_ID = 'GET_NEWS_BY_ID'
export const GET_NEWS_BY_ID_SUCCESS = 'GET_NEWS_BY_ID_SUCCESS'
export const GET_NEWS_BY_ID_FAILURE = 'GET_NEWS_BY_ID_FAILURE'

export const GET_MEDIA_LIST = 'GET_MEDIA_LIST'
export const GET_MEDIA_LIST_SUCCESS = 'GET_MEDIA_LIST_SUCCESS'
export const GET_MEDIA_LIST_FAILURE = 'GET_MEDIA_LIST_FAILURE'

export const GET_MEDIA_BY_ID = 'GET_MEDIA_BY_ID'
export const GET_MEDIA_BY_ID_SUCCESS = 'GET_MEDIA_BY_ID_SUCCESS'
export const GET_MEDIA_BY_ID_FAILURE = 'GET_MEDIA_BY_ID_FAILURE'

export const GET_USER_LIST = 'GET_USER_LIST'
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS'
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE'

export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_FAILURE = 'GET_USER_BY_ID_FAILURE'

export const GET_CONTACT = 'GET_CONTACT'
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS'
export const GET_CONTACT_FAILURE = 'GET_CONTACT_FAILURE'

export const GET_ADS_LIST = 'GET_ADS_LIST'
export const GET_ADS_LIST_SUCCESS = 'GET_ADS_LIST_SUCCESS'
export const GET_ADS_LIST_FAILURE = 'GET_ADS_LIST_FAILURE'

export const GET_PRODUCTS_BY_MANUFACTURER = 'GET_PRODUCTS_BY_MANUFACTURER'
export const GET_PRODUCTS_BY_MANUFACTURER_SUCCESS = 'GET_PRODUCTS_BY_MANUFACTURER_SUCCESS'
export const GET_PRODUCTS_BY_MANUFACTURER_FAILURE = 'GET_PRODUCTS_BY_MANUFACTURER_FAILURE'

export const GET_MANUFACTURER_INFO = "GET_MANUFACTURER_INFO"
export const GET_MANUFACTURER_INFO_SUCCESS = "GET_MANUFACTURER_INFO_SUCCESS"
export const GET_MANUFACTURER_INFO_FAILURE = "GET_MANUFACTURER_INFO_FAILURE"

export const GET_AGD_PRODUCT_LIST = 'GET_AGD_PRODUCT_LIST'
export const GET_AGD_PRODUCT_LIST_SUCCESS = 'GET_AGD_PRODUCT_LIST_SUCCESS'
export const GET_AGD_PRODUCT_LIST_FAILURE = 'GET_AGD_PRODUCT_LIST_FAILURE'


