import { Dialog, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Feedback } from '@material-ui/icons';
import "assets/css/style.css";
import angry from 'assets/icons/emoji/angry-face.svg';
import normal from "assets/icons/emoji/expressionless -face.svg";
import smile from 'assets/icons/emoji/smiling-face.svg';
import harvest from 'assets/icons/location.svg';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Button from 'components/CustomButtons/Button.jsx';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { LINK_API } from 'constants/API';
import { IMG_LOAD } from 'constants/index.js';
import strings from 'constants/strings';
import moment from 'moment';
import React from 'react';
import { OBJModel } from 'react-3d-viewer';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import ImageSlider from "../../components/ImageSlider/index.jsx";
import "../../components/ImageSlider/stylecss.css";
import "../../components/ImageSlider/stylescss.scss";
import OverviewGroup from "./overviewGroup.jsx";
import styles from './overviewStyle';
import RaidoStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Lightbox from 'react-image-lightbox';
import Image360 from "./image360"
import './lightbox.css'; // This only needs to be imported once in your app
// import SpeechText from "./speechText";
import Taste from './taste';

let Viewer;
const axios = require('axios');
const allStyle = {
	...styles,
	...RaidoStyles,
	hotSpotBlack: {
		backgroundColor: "black !important",
		borderRadius: "50%"
	}
}

const listImage = {
	image1: "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo1_dkxe91.jpg",
	image3: "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo3_d4wpac.jpg",
	image2: "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo2_krcknc.jpg"
}
const createTooltipSecond = (hotSpotDiv, args) => {
	const parentDiv = document.createElement('div');
	parentDiv.setAttribute("class", "hotspot")
	const outDiv = document.createElement('div');
	outDiv.setAttribute("class", "out")
	const inDiv = document.createElement('div');
	inDiv.setAttribute("id", args)
	inDiv.setAttribute("class", "in")
	inDiv.setAttribute("style", `background-image: url(${listImage[args]}); background-size: cover`)
	parentDiv.appendChild(inDiv)
	parentDiv.appendChild(outDiv)
	hotSpotDiv.appendChild(parentDiv);
}
const changeSceneSecond = async (name, x, y, id, hots, sceneId) => {
	if (name === "second") {
		const config = {
			"id": hots,
			"pitch": x,
			"yaw": y,
			"type": "scene",
			"cssClass": "custom-hotspot",
			"createTooltipFunc": createTooltipSecond,
			"clickHandlerFunc": () => changeSceneSecond("first", 10, 320, "image2", "hotSpot2", "second"),
			"createTooltipArgs": id,
			"sceneId": sceneId
		}
		const config_sub = {
			"id": "hotSpot3",
			"pitch": 530,
			"yaw": 180,
			"type": "scene",
			"cssClass": "custom-hotspot",
			"createTooltipFunc": createTooltipSecond,
			"clickHandlerFunc": () => changeSceneSecond("third", 530, 180, "image2", "hotSpot2", "second"),
			"createTooltipArgs": "image3",
			"sceneId": "third"
		}
		await Viewer.removeHotSpot('hotSpot1')
		await Viewer.removeHotSpot('hotSpot2')
		await Viewer.removeHotSpot('hotSpot3')
		await Viewer.loadScene(name)
		await Viewer.addHotSpot(config);
		await Viewer.addHotSpot(config_sub);
	}
	if (name === "first") {
		const config = {
			"id": hots,
			"pitch": x,
			"yaw": y,
			"type": "scene",
			"cssClass": "custom-hotspot",
			"createTooltipFunc": createTooltipSecond,
			"clickHandlerFunc": () => changeSceneSecond("second", 550, 25, "image1", "hotSpot1", "first"),
			"createTooltipArgs": id,
			"sceneId": sceneId
		}
		await Viewer.removeHotSpot('hotSpot1')
		await Viewer.removeHotSpot('hotSpot2')
		await Viewer.removeHotSpot('hotSpot3')
		await Viewer.loadScene(name)
		await Viewer.addHotSpot(config);
	}
	if (name === "third") {
		const config = {
			"id": hots,
			"pitch": x,
			"yaw": y,
			"type": "scene",
			"cssClass": "custom-hotspot",
			"createTooltipFunc": createTooltipSecond,
			"clickHandlerFunc": () => changeSceneSecond("second", 550, 25, "image1", "hotSpot1", "first"),
			"createTooltipArgs": id,
			"sceneId": sceneId
		}
		await Viewer.removeHotSpot('hotSpot1')
		await Viewer.removeHotSpot('hotSpot2')
		await Viewer.removeHotSpot('hotSpot3')
		await Viewer.loadScene(name)
		await Viewer.addHotSpot(config);
	}
}
class Overview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			open: false,
			tx: '',
			message: '',
			status: "",
			tomato: false,
			image: require('../../../../assets/img/products/cachuabivang.png'),
			shirts: "",
			text: ' ',
			selectedShirt: "",
			uuid: "",
			background: { rgb: { r: 255, g: 255, b: 255 } },
			gs1State: '',
			listLogo: [],
			images360: [],
			checkBox: "",
			titleId: "",
			activation_date: "",
			date: "",
			isSampleGLN: false,
			checkGroupDetail: 0,
			infoDetail: "",
			boxInfo: [],
			statusCT: 1,

			switchMode: false, // biến check chuyển đổi giữa ảnh thường và ảnh 360

			openLightBox: false, // biến check khi click ảnh để hiển thị ảnh full màn hình

			//Panorama Image
			panoImage: "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo1_dkxe91.jpg",
			image1: "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo1_dkxe91.jpg",
			image3: "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo3_d4wpac.jpg",
			image2: "https://res.cloudinary.com/agridential/image/upload/v1583076728/Production/photo2_krcknc.jpg",
			checkPanorama: false,
			changeImage: false,
			yaw: 320,
			pitch: 10,
			pitchInfo: 40,
			dynamicContent: ""
		};
		this.data = this.props.data;
		this.sendFeedBack = this.sendFeedBack.bind(this);
		this.handleProcessData = this.handleProcessData.bind(this);
		this.handleLoadingInfo = this.handleLoadingInfo.bind(this);
		this.hanldeClickImage = this.hanldeClickImage.bind(this);
		this.hotspotTooltip = this.hotspotTooltip.bind(this);
		this.handlePano = this.handlePano.bind(this)
		//this.changeScene = this.changeScene.bind(this)
	}
	async hotspotTooltip(hotSpotDiv) {
		const parentDiv = document.createElement('div');
		parentDiv.setAttribute("class", "hotspot")
		const outDiv = document.createElement('div');
		outDiv.setAttribute("class", "out")
		const inDiv = document.createElement('div');
		inDiv.setAttribute("id", "image2")
		inDiv.setAttribute("class", "in")
		inDiv.setAttribute("style", `background-image: url("${this.state.image2}"); background-size: cover`)

		await parentDiv.appendChild(inDiv)
		await parentDiv.appendChild(outDiv)
		await hotSpotDiv.appendChild(parentDiv);
	}
	hanldeClickImage() {
		if (this.state.changeImage === false) {
			this.setState({
				changeImage: true,
				yaw: 550,
				pitch: 25,
				pitchInfo: 40,
				panoImage: this.state.image2
			})

		} else {
			this.setState({
				changeImage: false,
				yaw: 340,
				pitch: 25,
				pitchInfo: 40,
				panoImage: this.state.image1
			})
		}
	}
	async componentDidMount() {
		if (this.props.data) {
			await this.handleLoadingInfo(this.props.data)
		}
		await this.setState({
			uuid: this.props.data.id
		})
		if (this.state.uuid === "00000003") {
			await this.handlePano(this.state.image1);
		}

	}
	async componentWillReceiveProps(preProps) {
		if (preProps.data !== this.props.data) {
			this.handleLoadingInfo(preProps.data)
		}
	}

	handlePano() {
		Viewer = window.pannellum.viewer('panorama', {
			"type": "equirectangular",
			"default": {
				"firstScene": "first"
			},
			"scenes": {
				"first": {
					"panorama": this.state.image1,
					"hotSpots": [
						{
							"id": "hotSpot2",
							"pitch": 10,
							"yaw": 320,
							"type": "scene",
							"cssClass": "custom-hotspot",
							"createTooltipFunc": createTooltipSecond,
							"clickHandlerFunc": () => changeSceneSecond("second", 550, 25, "image1", "hotSpot1", "first"),
							"createTooltipArgs": "image2",
							"sceneId": "second"
						},

					]
				},
				"second": {
					"panorama": this.state.image2,
					"hotSpots": []
				},
				"third": {
					"panorama": this.state.image3,
					"hotSpots": [],
				}

			},
			"autoLoad": true,
			"showControls": false,
			"orientationOnByDefault": true,
		});
		document.getElementById('pan-up').addEventListener('click', function () {
			Viewer.setPitch(Viewer.getPitch() + 10);
		});
		document.getElementById('pan-down').addEventListener('click', function () {
			Viewer.setPitch(Viewer.getPitch() - 10);
		});
		document.getElementById('pan-left').addEventListener('click', function () {
			Viewer.setYaw(Viewer.getYaw() - 10);
		});
		document.getElementById('pan-right').addEventListener('click', function () {
			Viewer.setYaw(Viewer.getYaw() + 10);
		});
		document.getElementById('image-2').addEventListener('click', () => changeSceneSecond("second", 550, 25, "image1", "hotSpot1", "first"));
		document.getElementById('image-1').addEventListener('click', () => changeSceneSecond("first", 10, 320, "image2", "hotSpot2", "second"));
		document.getElementById('image-3').addEventListener('click', () => changeSceneSecond("third", 530, 180, "image2", "hotSpot2", "second"));
		document.getElementById('zoom-out').addEventListener('click', function () {
			Viewer.setHfov(Viewer.getHfov() + 10);
		});
		document.getElementById('zoom-in').addEventListener('click', function () {
			Viewer.setHfov(Viewer.getHfov() - 10);
		});
		document.getElementById('fullscreen').addEventListener('click', function () {
			Viewer.toggleFullscreen();
		});
	}
	async handleLoadingInfo(data) {
		let tempImage = [];
		if (data.logo.length === 1) {
			await tempImage.push({
				src: data.logo[0].original,
			})
		}
		let language = strings.getLanguage() || "vi" ;
    let dynamicContent = ""
    if(language === 'en' && data.dynamicContent && data.dynamicContent.en){
      dynamicContent = data.dynamicContent.en
    }
    else if (language === 'vi' && data.dynamicContent && data.dynamicContent.vi){
      dynamicContent = data.dynamicContent.vi
    }
    else if(data.dynamicContent && !data.dynamicContent.en && !data.dynamicContent.vi){
      dynamicContent = data.dynamicContent 
		}

		await this.setState({
			idCode: data.showId,
			titleId: data.titleId,
			activation_date: data.activation_date,
			status: data.status,
			date: data.date,
			productDescription: data.data.productInfo.description ? data.data.productInfo.description : "",
			cooName: data.data.cooperativeInfo.name,
			cooAddress: data.data.cooperativeInfo.address,
			listLogo: data.logo.length === 1 ? tempImage : (data.logo ? data.logo : []),
			isSampleGLN: data.sample,
			gs1State: data.gs1State,
			checkGroupDetail: data.checkGroupDetail,
			infoDetail: data.detailInfo,
			boxInfo: data.boxInfo,
			checkPanorama: data.panorama,
			typeObject: data.data.type,
			dynamicContent: dynamicContent,
			images360: data.images360
		})
		if (data.data.type === "BOX") {
			this.setState({
				checkBox: true,
				productName: "Thùng " + data.data.productInfo.name
			})
		} else {
			if (data.data.type === "OBJECT") {
				this.setState({
					productName: data.data.objectInfo.name
				})
			} else {
				this.setState({
					productName: data.data.productInfo.name
				})
			}
		}
	}
	async handleProcessData() {
		if (this.data.type === 'COFFEE') {
			this.setState({
				gs1State: 'COFFEE'
			});
		}
		if (this.data.type === 'SPINACH') {
			this.setState({
				gs1State: 'SPINACH'
			});
		}
		if (this.data.type === 'HERB') {
			this.setState({
				gs1State: 'HERB'
			});
		}
		if (
			this.state.uuid === '000000001004700010000' ||
			this.state.uuid === '000000001004700010001' ||
			this.state.uuid === '000000001004700010002' ||
			this.state.uuid === '000000001004700010003' ||
			this.state.uuid === '000000001004700010004' ||
			this.state.uuid === '000000001004700010005' ||
			this.state.uuid === '000000001004700010006' ||
			this.state.uuid === '000000001004700010007' ||
			this.state.uuid === '000000001004700010008' ||
			this.state.uuid === '000000001004700010009' ||
			this.state.uuid === '000000001004700010010' ||
			this.state.uuid === '000000002003900020000'
		) {
			this.setState({
				image: require('../../../../assets/img/products/vuoncachuacherry.jpg'),
				status: false,
				tomato: true
			});
		}
		if (this.state.uuid === "400239700003400000000" ||
			this.state.uuid === "400239700003400000001" ||
			this.state.uuid === "400239700003400000002" ||
			this.state.uuid === "400239700003400000003" ||
			this.state.uuid === "400239700003400000004" ||
			this.state.uuid === "400239700003400000005") {
			this.setState({
				image: require("../../../../assets/img/vuonraumuong.jpg"),
				status: false,
				tomato: true
			})
		}
		if (this.state.uuid === "400239700004100010000" ||
			this.state.uuid === "400239700004100010001" ||
			this.state.uuid === "400239700004100010002" ||
			this.state.uuid === "400239700004100010003" ||
			this.state.uuid === "400239700004100010004" ||
			this.state.uuid === "400239700004100010005") {
			this.setState({
				image: require("../../../../assets/img/vuonrauthom.jpg"),
				status: false,
				tomato: true
			})
		}
		if (this.state.uuid === "000000019003900000000" ||
			this.state.uuid === "000000019004600010000" ||
			this.state.uuid === "000000019005300020000" ||
			this.state.uuid === "000000019006000030000") {
			this.setState({
				image: require("../../../../assets/img/vuondauden.jpg"),
				berry: true
			})
		}
		if (this.data.productInfo.name === 'Cà chua cocktail') {
			this.setState({
				image: require('../../../../assets/img/products/cachuacocktail.png'),
				tomato: true
			});
		}
		if (this.data.productInfo.name === 'Cà chua bi vàng') {
			this.setState({
				image: require('../../../../assets/img/products/cachuabivang.png'),
				tomato: true
			});
		}
		if (this.data.productInfo.name === 'Cà chua cherry') {
			this.setState({
				image: require('../../../../assets/img/products/cachuacherry.png'),
				tomato: true
			});
		}
		if (this.data.productInfo.name === 'XOÀI CÁT CHU CAO LÃNH') {
			this.setState({
				image: require('../../../../assets/img/products/xoaicatchu.png'),
				tomato: true
			});
		}

		if (this.data.type === 'BOX') {
			await this.setState({
				idCode: this.data.mapping.id
			});
		} else {
			const x = this.data.diary.length;
			var j = 0;
			for (j = 0; j < x; j++) {
				const y = this.data.diary[j].length;
				var i = 0;
				for (i = 0; i < y; i++) {
					const z = this.data.diary[j][i].length;
					var k = 0;
					for (k = 0; k < z; k++) {
						if (this.data.diary[j][i][k].step === 'BÁN LẺ') {
							this.setState({
								status: 1
							});
						}
					}
				}
			}

			const gln = this.state.uuid;
			if (!this.data.isSampleGLN && this.data.type !== 'ALCOHOL') {
				if (gln.length === 13) {
					await this.setState({
						idCode: this.data.mapping.id
					});
				} else if (this.data.type === "OBJECT") {
					await this.setState({
						idCode: this.state.uuid
					});
				}
				else if (this.data.type === "BOX") {
					await this.setState({
						idCode: this.data.mapping.id
					});
				}
				else {
					await this.setState({
						idCode: this.data.productInfo.gtin
					});
				}
			} else {
				await this.setState({
					idCode: this.data.objectInfo.idCode
				});
			}
		}
	}

	hideAlert() {
		this.setState({
			alert: null
		});
	}
	async sendFeedBack() {
		this.handleCloseDialog();
		const data = {
			uuidOfProduct: this.state.idCode,
			message: this.state.message,
			email: "test@gmail.com",
			phone: "09435644637"
		};

		this.setState({
			alert: (
				<SweetAlert
					// success
					style={{ display: 'block' }}
					title={strings.feedback_processing}
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
					showConfirm={false}
				>
					<div style={{ textAlign: 'center' }}>
						<img alt="{name}" src={IMG_LOAD} style={{ height: '100px' }} />
					</div>
				</SweetAlert>
			)
		});
		axios
			.post(LINK_API.SEND_FEEDBACK, data)
			.then(async () => {
				this.setState({
					alert: (
						<SweetAlert
							success
							style={{ display: 'block', marginTop: '-270px' }}
							title={strings.feedback_success}
							onConfirm={() => this.hideAlert()}
							onCancel={() => this.hideAlert()}
							confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
						/>
					)
				});
			})
			.catch(() => {
				this.setState({
					alert: (
						<SweetAlert
							error
							style={{ display: 'block' }}
							title={strings.feedback_failed}
							onConfirm={() => this.hideAlert()}
							onCancel={() => this.hideAlert()}
							confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.success}
						/>
					)
				});
			});
	}
	handleOpenDialog = () => {
		this.setState({ openDialog: true });
	};
	handleCloseDialog = () => {
		this.setState({ openDialog: false });
	};
	handleChange = () => (event) => {
		this.setState({ message: event.target.value });
	};
	handleBtCt = () => {
		if (this.state.statusCT) {
			document.getElementById('btCt').innerHTML = strings.view_detail_overview;
			document.getElementById('detailProduct').style = 'display: none; width:100%';
			this.setState({
				statusCT: 0
			});
		} else {
			document.getElementById('btCt').innerHTML = strings.collapse;
			document.getElementById('detailProduct').style = 'display: flex;  justify-content: center ; width: 100%; flex-flow: row; text-align: justify;';
			this.setState({
				statusCT: 1
			});
		}
	};

	handleRentOpen = () => {
		this.setState({ rent: true });
	};

	handleRentClose = () => {
		this.setState({ rent: false });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	showBlockhainAlert = (tx) => {
		this.setState({ open: true, tx: tx });
	};
	selectShirt(thumb) {
		this.setState({ selectedShirt: thumb }, (_) => this.forceUpdate());
	}

	render() {
		const { classes } = this.props;
		//const selector = useCallback(voices => [...voices].find(v => v.lang === 'vi-VN'), []);
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }} className={classes.noPadding}>
					<Card>
						<CardBody>
							<GridContainer
								style={{
									paddingTop: '10px',
									paddingBottom: '10px',
									display: 'flex',
									justifyContent: 'center',

								}}
							//Logo
							>
								{this.state.listLogo.length > 1 ? <GridItem xs={12} className="styleBackgroundOverview" id="mobileImg">
									<ImageSlider items={this.state.listLogo}
										lazyLoad={true}
										showFullscreenButton={false}
										showPlayButton={false}
										infinite={true}
										autoPlay={true}
										showNav={false}
										useBrowserFullscreen={false}
										disableArrowKeys={true} />
								</GridItem> :
									this.state.listLogo[0] ?
										this.state.typeObject !== "XETANG" && this.state.typeObject !== "XETANG1" && this.state.typeObject !== "CITY" ?
											!this.state.switchMode ? <div className="styleBackgroundOverview"  >
												<img alt="logo" style={{ width: "500px", cursor: "pointer" }} onClick={() => this.setState({ openLightBox: true })} src={this.state.listLogo[0].src} />
												{this.state.openLightBox && (
													<Lightbox
														mainSrc={this.state.listLogo[0].src}
														// nextSrc={this.state.listLogo[0].src}
														// prevSrc={this.state.listLogo[0].src}
														onCloseRequest={() => this.setState({ openLightBox: false })}
													/>
												)}
												{this.state.images360.length > 0 ?
													<FormControlLabel
														control={
															<Switch
																checked={this.state.switchMode}
																color="success"
																onChange={event => this.setState({ switchMode: !this.state.switchMode })}
																value={this.state.switchMode}

															/>
														}
														classes={{
															label: classes.label
														}}
														label={strings.Image360}
													/> : null}
											</div> : null
											:
											(this.state.typeObject === "XETANG" ? (
												<OBJModel width={390} height={371} src={this.state.listLogo[0].original} texPath="" />

											) : (this.state.typeObject === "CITY" ?
												<div style={{ width: "100%" }}>
													<div id="panorama">
														<div id="controls">
															<div className="ctrl" id="pan-up">&#9650;</div>
															<div className="ctrl" id="pan-down">&#9660;</div>
															<div className="ctrl" id="pan-left">&#9664;</div>
															<div className="ctrl" id="pan-right">&#9654;</div>
															<div className="ctrl" id="image-1">1</div>
															<div className="ctrl" id="image-2">2</div>
															<div className="ctrl" id="image-3">3</div>
															<div className="ctrl" id="zoom-in">&#43;</div>
															<div className="ctrl" id="zoom-out">&minus;</div>
															<div className="ctrl" id="fullscreen">&#x2922;</div>
														</div>
													</div>
												</div>
												:
												<div class="sketchfab-embed-wrapper">
													<iframe title="A 3D model" className="style3D" src="https://sketchfab.com/models/14c74d148326448c8edb5fee81be3894/embed?autostart=1" frameborder="0" allow="autoplay; fullscreen; vr" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
												</div>))
										: null
								}
								{this.state.switchMode ? <div className="styleBackgroundOverview">
									<Image360 />
									<FormControlLabel
										control={
											<Switch
												checked={this.state.switchMode}
												color="success"
												onChange={event => this.setState({ switchMode: !this.state.switchMode })}
												value={this.state.switchMode}
											/>
										}
										classes={{
											label: classes.label
										}}
										label={strings.Image360}
									/>
								</div> : null}
								<GridItem xs={12} md={12} sm={12} id="text" className={classes.textCenter}>
									<Typography gutterBottom variant='h1' className="pructName">
										{this.state.productName}
									</Typography>

								</GridItem>
								<GridItem xs={12} md={10} sm={12} className="styleMarginAddress">
									<GridContainer className="flexrowharvest">
										<div className="iconhv">
											<img alt="harvest" className={classes.badgeIcon} src={harvest} />
										</div>
										<GridItem xs={10} md={10} sm={10} className="cooInfo">
											<p className="cooName">{this.state.cooName}</p>
											<p className="cooAdd">{this.state.cooAddress}</p>
										</GridItem>
									</GridContainer>
								</GridItem>

								<GridItem xs={12} id="text" className={classes.textCenter}>
									{this.state.isSampleGLN ? (this.state.typeObject !== "CITY" ?
										<Typography variant='h1' className={classes.textSample}>
											{strings.sample_product}
										</Typography> : null
									) : (
											''
										)}

									{this.state.checkBox ? (
										<div>
											<Typography variant='h1' className={classes.textLight}>
												{this.state.titleId}
											</Typography>
											<Typography variant='h1' className={classes.textCode}>
												{this.state.idCode}
											</Typography>
										</div>
									) : (
											<div>
												<Typography variant='h1' className={classes.textLight}>
													{this.state.titleId}
												</Typography>
												<div
													style={{
														display: 'flex',
														flexFlow: 'row',
														justifyContent: 'center',
														alignItems: 'center'
													}}
												>
													<Typography variant='h1' className={classes.textCode}>
														{this.state.idCode}
													</Typography>
													{this.state.gs1State === 'COFFEE' ? (
														<img
															alt="lookup"
															className="imageFind"
															src={require('assets/icons/magnifier.svg')}
															onClick={() => {
																window.open(
																	'http://gepir.gs1.org.vn/getItembyGTIN.php?keyValue=08938522156580',
																	'_blank'
																);
															}}
														/>
													) : this.state.gs1State === 'HERB' ||
														this.state.gs1State === 'SPINACH' ? (
																<img
																	alt="lookup2"
																	className="imageFind"
																	src={require('assets/icons/magnifier.svg')}
																	onClick={() => {
																		window.open(
																			'http://gepir.gs1.org.vn/getItembyGTIN.php?keyValue=08938525767059',
																			'_blank'
																		);
																	}}
																/>
															) : null}
												</div>
											</div>
										)}

								</GridItem>
								{!this.state.isSampleGLN ? this.state.status ? <GridItem xs={12}>
									<Typography
										variant='h1'
										className={classes.textLight}
										style={{ marginTop: '10px' }}
									>
										{strings.status}
									</Typography>
									<Typography variant='h1' className={classes.textCode}>
										{this.state.status}
									</Typography>
								</GridItem> : null : null}
								{
									this.state.date ? <GridItem xs={12}>
										<Typography
											variant='h1'
											className={classes.textLight}
											style={{ marginTop: '10px' }}
										>
											{this.state.activation_date}
										</Typography>
										<Typography variant='h1' className={classes.textCode}>
											{moment(this.state.date).format('DD/MM/YYYY')}
										</Typography>
									</GridItem> : null
								}
								<div id="detailProduct" style={{ textAlign: 'justify', display: "flex", justifyContent: "center", width: "100%", flexFlow: "row" }}>
									<GridItem xs={12} md={10} sm={12} >
										{this.state.productDescription ? (
											<div>
												<p className="detailtitle">{strings.introduction_product}</p>
												{this.state.productDescription.split('\n').map((item) => {
													return (
														<Typography className="textdes">
															{item}
														</Typography>
													);
												})}
												<hr className="LineBreakDes" />
											</div>
										) : null}
										{
											this.state.checkGroupDetail === 0 ? null :
												this.state.checkGroupDetail === 1 ? (
													<div>
														{Object.keys(this.state.infoDetail).map((overview, index) => (
															<div key={index}>
																<p className="detailtitle">{overview}</p>
																<Typography className="textdes" style={{ marginTop: '10px' }}>
																	{this.state.infoDetail[overview]}
																</Typography>
																{index !== this.state.infoDetail.length - 1 ? <hr className="LineBreakDes" /> : null}

															</div>
														))}
													</div>
												) : this.state.checkGroupDetail === 2 ?
														<OverviewGroup overview={this.state.infoDetail} /> : (
															<div>
																{this.state.infoDetail.map((overview, index) => (
																	<div key={index}>
																		<p className="detailtitle">{overview.key}</p>
																		<Typography className="textdes" style={{ marginTop: '10px' }}>
																			{overview.value}
																		</Typography>
																		{index !== this.state.infoDetail.length - 1 ? <hr className="LineBreakDes" /> : null}

																	</div>
																))}
															</div>
														)
										}
										{
											this.state.dynamicContent && this.state.dynamicContent !== "" ?
												<div>
													<hr className="LineBreakDes" />
													{renderHTML(this.state.dynamicContent)}
													<hr className="LineBreakDes" />
												</div>
												:
												null
										}
										{this.state.boxInfo.map((box) => (
											<div>
												<p className="detailtitle">{box.key}</p>
												<Typography className="textdes" style={{ marginTop: '10px' }}>
													{box.value}
												</Typography>

												<hr className="LineBreakDes" />
											</div>
										))}
										{this.state.typeObject === "FRUIT" ?
											<GridContainer style={{ marginTop: '50px' }}>
												<GridItem xs={12}>
													<Taste />
												</GridItem>
											</GridContainer> : null}
									</GridItem>
								</div>
							</GridContainer>
							{!this.state.isSampleGLN ?
								<GridContainer style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>

									<GridItem xs={4} sm={3} md={3} lg={3}>
										<div className="paddingEmoji">
											<img src={smile} alt="smile"
												id="imageSmile"
												onClick={() => this.handleOpenDialog("Hài lòng")}
												className="iconHover" />
										</div>
									</GridItem>

									<GridItem xs={4} sm={3} md={3} lg={3}>
										<div className="paddingEmoji">
											<img src={normal} alt="normal"
												id="imageNormal"
												onClick={() => this.handleOpenDialog("Bình thường")}
												className="iconHover" />
										</div>
									</GridItem>

									<GridItem xs={4} sm={3} md={3} lg={3}>
										<div className="paddingEmoji">
											<img src={angry} alt="angry"
												id="imageAngry"
												onClick={() => this.handleOpenDialog("Không hài lòng")}
												className="iconHover" />
										</div>
									</GridItem>
								</GridContainer> : null}
							<GridContainer justify="center">
								{(this.data.type === "PRODUCT" || this.data.type === "PRODUCTTYPE" || this.data.type === "BOX") ? (
									<GridContainer style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>

										<GridItem xs={4} sm={3} md={3} lg={3}>
											<div className="paddingEmoji">
												<img src={smile} alt="smile"
													id="imageSmile"
													onClick={() => this.handleOpenDialog("Hài lòng")}
													className="iconHover" />
											</div>
										</GridItem>

										<GridItem xs={4} sm={3} md={3} lg={3}>
											<div className="paddingEmoji">
												<img src={normal} alt="normal"
													id="imageNormal"
													onClick={() => this.handleOpenDialog("Bình thường")}
													className="iconHover" />
											</div>
										</GridItem>

										<GridItem xs={4} sm={3} md={3} lg={3}>
											<div className="paddingEmoji">
												<img src={angry}
													id="imageAngry" alt="angry"
													onClick={() => this.handleOpenDialog("Không hài lòng")}
													className="iconHover" />
											</div>
										</GridItem>
									</GridContainer>
								) : null}

								<Button id="btCt" size="sm" color="success" onClick={this.handleBtCt} className="btnfb">
									{strings.collapse}
								</Button>
							</GridContainer>
						</CardBody>
					</Card>

					<Dialog
						open={this.state.openDialog}
						onClose={this.handleCloseDialog}
						aria-labelledby="form-dialog-title"
						PaperProps={{
							classes: {
								root: classes.paper
							}
						}}
					>
						<Card>
							<CardHeader color="success" icon>
								<CardIcon color="success">
									<Feedback />
								</CardIcon>
								<h4 className={classes.cardIconTitle}>{strings.product_feedback}</h4>
							</CardHeader>
							<CardBody style={{ textAlign: 'justify' }}>
								<h6 className={classes.textField}> {strings.product_feedback_description}</h6>
								<TextField
									id="standard-multiline-flexible"
									label={strings.product_feedback_content}
									value={this.state.feedbackContent}
									onChange={this.handleChange('feedbackContent')}
									className={classes.textField}
									margin="normal"
									fullWidth
								/>
								<TextField
									id="standard-multiline-flexible"
									label={strings.product_feedback_content_more}
									multiline
									rowsMax="4"
									value={this.state.multiline}
									onChange={this.handleChange('multiline')}
									className={classes.textField}
									margin="normal"
									fullWidth
								/>
								<Button color="success" onClick={this.sendFeedBack}>
									{strings.send}
								</Button>
							</CardBody>
						</Card>
					</Dialog>
				</GridItem>
				{this.state.alert}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		data: state.overview
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
		getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(allStyle)(Overview));
