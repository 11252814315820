import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Add from "@material-ui/icons/CropFree";
import US from "assets/icons/us.svg";
import Vietnam from "assets/icons/vietnam.svg";
import axios from 'axios';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { LINK_API } from '../../../../constants/API';
import DataSample from "./data";
import CustomDropdown from './DropDown/index.jsx';

// import picture from "assets/image/pictureicon.png";
var styles = {
    background: {
        width: "100%",
        margin: "auto",
    },
    loadingBackground: {
        width: "100%",
        height: "100vh",
        backgroundColor: "#fff",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"

    },
    textBoldTitle: {
        color: "#444345",
        fontWeight: "500",
        textAlign: "center"
    },
    textTitle: {
        color: "#444345",
        textAlign: "center",
        fontSize: "18px",
    },
    buttonActive: {
        border: "1px solid #4a443a !important",
        borderRadius: "2px !important",
        fontSize: "12px",
        color: "#4a443a !important",
        backgroundColor: "#fff",
        fontWeight: "400 !important",
        padding: "8px 20px !important",
        textTransform: "none !important",
    },
    paddingDropdown: {
        padding: "0px 0px !important"
    },
    paddingProduct: {
        padding: "0px 3px !important"
    },
    MarginProduct:{
        margin: "0px 5px !important"
    }
};

class InfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            load: true,
            timeout: false,
            type: false,
            usernameState: "success",
            lookup_data: {
                component: null,
            },
            data: [],
        }
        this.lookup_gln = this.props.match.params.id;
        this.onChange = this.onChange.bind(this);
        this.routeScan = this.routeScan.bind(this);
        this.routeChange = this.routeChange.bind(this);
    }

    routeChange() {
        if (!this.state.gln) return;
        let path = '/q/' + this.state.gln;
        this.props.history.push(path);
      }
  onChange(e) {
    this.setState({
      gln: e.target.value
    })
  }
    async componentDidMount() {

        await axios.get(LINK_API.GET_PRODUCT)
            .then(async res => {
                // console.log(res.data.data)
                let data = [];
                if (res.data.errorCode === 1) {
                    if(res.data.data.length !== 0){
                        data = res.data.data.map(item => {
                            return {
                                name: item.nameOfProduct,
                                id: item.idToRetrieve[0].startId,
                                corporation: item.nameOfEnterprise,
                                url: item.image[0] ? item.image[0].secure_url : "https://res.cloudinary.com/agridential/image/upload/v1570694727/Logo/agd_black_text_aqarfl.png"
                            }
                        }
                        )

                        for (var index = 0; index < DataSample.length ; index ++){
                            data.push(DataSample[index]);
                        }

                        await this.setState({
                            data: data
                        })
                    }
                    else{
                        await this.setState({
                            data: DataSample
                        })
                    }

                }
                else{
                    await this.setState({
                        data: DataSample
                    })
                }
            })
            .catch(err => {
                this.setState({
                    data: DataSample
                })
            })

    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }
    routeScan() {
        let path = '/scan';
        this.props.history.push(path);
      }
    render() {
        const AGD = "https://res.cloudinary.com/agridential/image/upload/v1570694727/Logo/agd_black_text_aqarfl.png";
        const { classes } = this.props;
        return (
            <div  style={{ backgroundColor: "#FCF9F4" }}>
                <GridContainer style={{ backgroundColor: "#0f7343", width: "101%" }}>
                    <GridItem xs={4} sm={3} md={3} lg={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "white", marginTop: "7px" }}>
                        <p className="styleHotlineProduct">Hotline: 0908 444 095</p>
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3} lg={3} style={{ display: "flex", alignItems: "center", color: "white", marginTop: "7px" }}>
                        <p className="styleContactProduct" >contact@vietnamblockchain.asia</p>
                    </GridItem>
                    <GridItem xs={2} sm={3} md={3} lg={3}></GridItem>
                    <GridItem xs={3} sm={3} md={3} lg={3} style={{ display: "flex", alignItems: "center", color: "white", justifyContent: "center", marginTop: "-5px" }}>
                        <CustomDropdown
                            buttonText={<span><img alt="Vietnam"style={{ height: "30px" }} src={Vietnam} /></span>}
                            size="sm"
                            buttonColor="transparent"
                            hoverColor="success"
                            dropdownList={[
                                <span><img  alt="VI"style={{ height: "30px" }} src={Vietnam} /> VI</span>,
                                <span><img alt="EN" style={{ height: "30px" }} src={US} /> EN</span>
                            ]}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer style={{ backgroundColor: "white", height: "70px"}} className={classes.MarginProduct}>
                    <GridItem xs={4} sm={4} md={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img alt="AGD" className="styleLogoProduct" src={AGD}></img>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <GridContainer style={{ display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "row" }}>
                            <div className="styleSearchID" >
                                <CustomInput
                                    className="styleSearchID"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses,
                                        // onChange: this.handleChange('username'),
                                    }}
                                    inputProps={{
                                        value: this.state.username,
                                        autoComplete: "off",
                                        onChange: this.onChange,
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                className={classes.inputAdornment}
                                                style={{cursor: "pointer"}}
                                                onClick={this.routeScan}
                                            >
                                                <Add />
                                            </InputAdornment>
                                        ),
                                        placeholder: "Mã sản phẩm"
                                    }}
                                />
                            </div>
                            <Button color="success" size="sm" style={{ marginLeft: "10px" }} onClick={this.routeChange}>Tra cứu</Button>
                        </GridContainer>
                    </GridItem>
                    <GridContainer style={{ backgroundColor: "#f9f9f9" }}>
                        {this.state.data.map((item, key) => {
                            return (
                                <GridItem xs={6} sm={6} md={4} lg={3} className={classes.paddingProduct}>
                                    {/* <Card className="styleCardProduct">
                                    <CardBody style={{ textAlign: "center" }}>
                                        {item.url === AGD?(
                                            <div className="styleImageProduct2">
                                                <img className="styleImageOfProduct2" src={item.url} />
                                            </div>
                                        ):(
                                            <div className="styleImageProduct">
                                                <img className="styleImageOfProduct" src={item.url} />
                                            </div>
                                        )
                                        }
                                    </CardBody>
                                    <div className="insideCardProduct">
                                        <div className="HeightInfoProduct">
                                            <Typography variant="h1" className="styleNameProduct">
                                                {item.name}
                                            </Typography>
                                            <div className="styleCenterCor">
                                            <Typography variant="h1" className="styleCorProduct">
                                                {item.corporation}
                                            </Typography>
                                            </div>
                                        </div>
                                        <GridContainer>
                                            <GridItem xs={6} sm={6} md={6}>
                                                <Button
                                                    target="_blank"
                                                    href={"#"}
                                                    color="success"
                                                    size="sm"
                                                    className="styleButtonSm"
                                                >
                                                    Đặt hàng
                                                </Button>
                                            </GridItem>
                                            <GridItem xs={6} sm={6} md={6}>
                                                <Button
                                                    target="_blank"
                                                    href={"https://portal.agridential.vn/lookup/" + item.id}
                                                    color="success"
                                                    size="sm"
                                                    className="styleButtonSm"
                                                >
                                                    Truy xuất
                                                </Button>
                                            </GridItem>
                                        </GridContainer>
                                        </div>
                                </Card> */}
                                    <div class="container page-wrapper">
                                        <div class="page-inner">
                                            <div class="row">
                                                <div class="el-wrapper">
                                                    <div class="box-up">
                                                        {item.url === AGD ? <div className="styleImageProduct2">
                                                                                <img alt="Product" className="styleImageOfProduct2" src={item.url} />
                                                                            </div> :
                                                            <img class="img" src={item.url} style={{ height: "186px" }} alt="url" />}
                                                        <div class="img-info">
                                                            <div class="info-inner">
                                                                <span class="p-name">{item.name}</span>
                                                                <span class="p-company">{item.corporation}</span>
                                                            </div>
                                                            <div class="a-size">
                                                                <Button
                                                                    target="_blank"
                                                                    href={"https://portal.agridential.vn/lookup/" + item.id}
                                                                    color="success"
                                                                    size="sm"
                                                                    className="styleButtonSm"
                                                                >
                                                                    Truy xuất
                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="box-down">
                                                        <div class="h-bg">
                                                            <div class="h-bg-inner"></div>
                                                        </div>

                                                        <a class="cart" href="# ">
                                                            <span class="price">Mua hàng</span>
                                                            <span class="add-to-cart">
                                                                <span class="txt">Mua hàng</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </GridItem>
                            )
                        })}
                    </GridContainer>
                </GridContainer>

            </div>
        );
    }
}


InfoPage.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(withRouter(InfoPage));
