import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { GET_PRODUCT_LIST } from "actions/types.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import { truncate } from "../../../utils";
const Products = (props) => {
  // const [tab, setTab] = React.useState(0);
  const getProductList = props.getProductList;
  React.useEffect(() => {
    getProductList();
  }, [getProductList]);
  const productList = props.productList.data;
  const [listProduct, setList] = React.useState([]);
  React.useEffect(() => {
    if (productList) {
      setList(productList);
    }
  }, [productList]);

  const handleSeeDetail = (id) => [props.history.push("/q/" + id)];

  const handleSeeOrigin = (id) => [props.history.push("/ho-kinh-doanh/" + id)];

  // const changeTab = (tab) => setTab(tab);

  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col md="12">
            <div className="w-100 pb-2">
              <div className="border-product d-inline-block py-1 mt-3 ">
                <span className="title-section font-weight-bold">SẢN PHẨM MỚI</span>
              </div>
              <a className="fl-right pr-3 mt-4 font-size-xs" href="/san-pham">
                Xem tất cả <ChevronRightIcon />
              </a>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col md="12">
            <div className="cms-grid-filter text-center">
                <ul>
                  <li><a className={tab === 0 ? 'selected' : ''} onClick={() => changeTab(0)}>Chợ Hoà Cường</a></li>
                  <li><a className={tab === 1 ? 'selected' : ''} onClick={() => changeTab(1)}>Agridential</a></li>
                </ul>
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col md="12">
            <div className="mt-4 d-flex flex-wrap-content flex-item-product">
              {listProduct.map((item, idx) => {
                if (idx < 8) {
                  return (
                    <div
                      className="flex-stretch-card-product position-relative mb-2"
                      key={idx}
                    >
                      <Card className="no-transition w-100 card-product">
                        <div
                          className="p-4 d-flex flex-column align-items-center card-container"
                        >
                          <div className="ava-product text-center">
                            <img
                              onClick={() =>
                                handleSeeDetail(item.idToRetrieve[0].startId)
                              }
                              className="img-product rounded xy-center"
                              src={
                                item.image && item.image.length && item.image[0] && item.image[0].secure_url
                                  ? item.image[0].secure_url
                                  : "https://res.cloudinary.com/agridential/image/upload/v1600922897/AGDImage/Asset_3_2x_yapsvy.png"
                              }
                              alt="product1"
                            />
                          </div>
                          <div className="info-product-card w-100">
                            <p
                              onClick={() =>
                                handleSeeDetail(item.idToRetrieve[0].startId)
                              }
                              title={item.nameOfProduct}
                              className="font-weight-bold mt-3 mb-0 product-lable"
                            >
                              {truncate(item.nameOfProduct, 25)}
                            </p>
                            <p
                              onClick={() => handleSeeOrigin(item.ownedBy)}
                              className="origin-lable mb-0"
                              title={item.nameOfEnterprise}
                            >
                              {truncate(item.nameOfEnterprise, 25)}
                            </p>
                            {/* <p className='text-warning font-weight-bold'>$1.0</p> */}
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                }
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  productList: state.productList,
});
const mapDispatchToProps = (dispatch) => ({
  getProductList: () => dispatch({ type: GET_PRODUCT_LIST }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Products)
);
