import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import EN from "assets/img/flags/GB.png";
import VN from "assets/img/flags/VN.png";
import FR from "assets/img/flags/FR.png";
import qrIcon from 'assets/img/qrcodesarch.svg';
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import strings from "constants/strings";
import PropTypes from "prop-types";
import React from "react";
import cookie from 'react-cookies';
// import { Manager, Target, Popper } from "react-popper";
import { withRouter } from 'react-router-dom';
// core components
import CustomInput from "../CustomInput/CustomInput.jsx";
import HeaderLookupStyle from "./HeaderLookupStyle.jsx";
import { connect } from 'react-redux';

const language = ["vi", "en", "fr"];
const language_img = {
  "vi": VN,
  "en": EN,
  "fr": FR
}


class HeaderLookup extends React.Component {
 constructor(props) {
    super(props);
    this.state = {
      open: false,
      openDialog: false,
      gln: null,
      language: cookie.load("language")?cookie.load("language"):'vi',
      //language: 'vi',
      openLanguageMenu: false,
      anchorEl: null,
    };
    this.routeChange = this.routeChange.bind(this);
    this.routeScan = this.routeScan.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleLanguageItemClick = this.handleLanguageItemClick.bind(this);
    this.handleLanguageMenu = this.handleLanguageMenu.bind(this);
    this.handleLanguageMenuClose = this.handleLanguageMenuClose.bind(this);
  }

 async componentDidMount() {
    // const lan = cookie.load("language");
    // if (lan) {
    //   strings.setLanguage(lan);
    //   this.setState({ language: lan })
    // }
    // else {
    //   this.setState({ language: strings.getLanguage() })
    // }
   // console.log("Hello", this.props.overview)
    if(this.props.overview){
      if(this.props.overview.data.setting){
       // console.log("Setting", this.props.overview.data.setting)
        if(this.props.overview.data.setting.defaultLanguage){
         // console.log("Set language",  this.props.overview.data.setting.defaultLanguage )
          this.setState({
            language: this.props.overview.data.setting.defaultLanguage
          })
        }
       
      }
    }
  }
  async componentDidUpdate(preProps){
   // console.log(this.props.overview)
    if(this.props.overview !== preProps.overview){
        if(this.props.overview.data.setting){
          if(this.props.overview.data.setting.defaultLanguage){
            //console.log("Set",  this.props.overview.data.setting.defaultLanguage )
            this.setState({
              language: this.props.overview.setting.defaultLanguage
            })
        }
      }
    }
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleLanguageMenu = event => {
    this.setState({ openLanguageMenu: !this.state.openLanguageMenu, anchorEl: event.currentTarget });
  };

  handleLanguageMenuClose = event => {
    this.setState({ openLanguageMenu: false });
  };

  handleLanguageItemClick = async (event, index) => {
    //console.log(index);
    await this.setState({ language: index });
    this.setState({ openLanguageMenu: false });
    await cookie.save('language', index, { path: '/' });
   window.location.reload();
  };

  onChange(e) {
    this.setState({
      gln: e.target.value
    })
  }

  routeChange() {
    if (!this.state.gln) return;
    let path = '/q/' + this.state.gln;
    this.props.history.push(path);
    //console.log("Check")
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  routeScan() {
    let path = '/scan';
    this.props.history.push(path);
  }
  render() {
    const { classes } = this.props;

    const AGRHEADER = "https://res.cloudinary.com/agridential/image/upload/c_scale,w_239/v1570694727/Logo/agd_black_text_aqarfl.png";
    const VBC = "https://res.cloudinary.com/agridential/image/upload/c_scale,w_272/v1570695532/Logo/vbcblack_voirvu.png";
    strings.setLanguage(this.state.language);

    const renderLanguageMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.state.openLanguageMenu}
        onClose={this.handleLanguageMenuClose}
        style={{ marginLeft: "16px", marginTop: "33px" }}
      >
        {language.map((option, index) => (
          <MenuItem
            key={option}
            //   disabled={index === }
            selected={index === this.state.selectedIndex}
            onClick={event => this.handleLanguageItemClick(event, option)}
          >
            <img
              src={language_img[option]}
              width="23"
              height="17"
              alt=""
            />
          </MenuItem>
        ))}

      </Menu>
    );
    return (
      <div >
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <GridContainer className={classes.root}>
          <GridItem xs={12} sm={12} md={12} lg={12} className={classes.noPadding}>
          <GridItem xs={9} sm={9} md={9} className={classes.paddingLogo}>
            <img className={classes.vbc} src={VBC} alt=""/>
            <img className={classes.agd} src={AGRHEADER} alt=""/>
          </GridItem>
          <GridItem xs={3} sm={3} md={3} className={classes.language} >
            <Button className={classes.languageButton}>
              <img
                src={language_img[this.state.language]}
                height="24"
                className="languageImg"
                onClick={this.handleLanguageMenu}
                alt=""
              />
            </Button>
          </GridItem>
          </GridItem>
        </GridContainer>
        </div>
       
        <div className={classes.headerBar}>
        <div className={classes.lookupBar}>
          <div className="inputForm">
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search
            }}
            inputProps={{
              placeholder: strings.input_lookup_code,
              disableUnderline: true,
              inputProps: {
                "aria-label": strings.input_lookup_code,
                className: classes.searchInput,
              },
              classes:{
                input: classes.placeholdersearch,
                underline: classes.underlinesearch
              },
              onChange: this.onChange
            }}
          />
          </div>
          <div className="qrButton">
          <Button className={classes.qrButton}>
            <img
              src={qrIcon}
              alt="wonder"
              className="qrImage"
              onClick={this.routeScan}
            />
          </Button>
          </div> 
          <div className="searchButton">
          <Button
            color="transparent"
            onClick={this.routeChange}
            className="searchButtonInside"
            // round
          >
            {strings.search}
          </Button>
          </div> 
        </div>
        {renderLanguageMenu}
        </div>
      </div>
    );
  }
}

HeaderLookup.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};
const mapStateToProps = (state) => {
	return {
		overview: state.overview
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
    getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
    cleanStore: () => dispatch({type: "CLEAN_STORE"})
	}
}
export default withStyles(HeaderLookupStyle)(withRouter(connect(mapStateToProps,mapDispatchToProps)(HeaderLookup)));
