import React, { useState, useEffect } from "react";
import CardShadow from "components/Card/Card.jsx";
import { Container, Row, Card, Col, Button } from "reactstrap";
import { Pagination } from "@material-ui/lab";
import {
  GET_PRODUCTS_BY_MANUFACTURER,
  GET_MANUFACTURER_INFO,
} from "actions/types.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SproutIcon from "mdi-react/SproutIcon";
import { stripHtml } from "../../../utils";

const renderProductList = (props) => {
  const uuid = props.match.params.id;
  const getProductList = props.getProductList;

  useEffect(() => {
    getProductList({ uuid });
  }, [getProductList]);

  const productList = props.productList;
  const [listProduct, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (productList) {
      setList(productList);
    }

    const total = listProduct.length;

    if (total) {
      const numberPage = Math.ceil(total / 8);
      setCount(numberPage);
    }
  }, [productList]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  if (!(listProduct && listProduct.length)) {
    return (
      <Row className="mt-3">
        <Col md="12">
          <div className="w-100 border-product pb-2">
            <div className="bg-warning d-inline-block p-2 mt-3 ">
              <span className="text-white">
                <SproutIcon className="text-white mr-1" /> Danh sách sản phẩm
              </span>
            </div>
          </div>
        </Col>
        <Col className="mt-3">
          <p className="text-center">Không có dữ liệu</p>
        </Col>
      </Row>);
  }

  return (
    <>
      <Row className="mt-3">
        <Col md="12">
          <div className="w-100 border-product pb-2">
            <div className="bg-warning d-inline-block p-2 mt-3 ">
              <span className="text-white">
                <SproutIcon className="text-white mr-1" /> Danh sách sản phẩm
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="mt-4 d-flex flex-wrap-content flex-item-product">
            {listProduct.map((item, idx) => {
              if (idx >= (page - 1) * 8 && idx < page * 8) {
                return (
                  <div
                    className="flex-stretch-card-product position-relative mb-2"
                    key={idx}
                  >
                    <Card className="no-transition w-100 card-product">
                        <div
                          className="p-4 d-flex flex-column align-items-center card-container"
                        >
                          <div className="ava-product text-center">
                            <img
                              onClick={() =>
                                props.handleSeeDetail(item.idToRetrieve[0].startId)
                              }
                              className="img-product rounded xy-center"
                              src={
                                item.image[0] && item.image[0].secure_url
                                  ? item.image[0].secure_url
                                  : "https://res.cloudinary.com/agridential/image/upload/v1600922897/AGDImage/Asset_3_2x_yapsvy.png"
                              }
                              alt="product1"
                            />
                          </div>
                          <div className="info-product-card w-100">
                            <p
                              onClick={() =>
                                props.handleSeeDetail(item.idToRetrieve[0].startId)
                              }
                              className="font-weight-bold mt-3 mb-0 product-lable"
                            >
                              {item.nameOfProduct}
                            </p>
                            {/* <p
                              onClick={() => handleSeeOrigin(item.ownedBy)}
                              className="origin-lable mb-0"
                            >
                              {item.nameOfEnterprise}
                            </p> */}
                          </div>
                        </div>
                      </Card>
                  </div>
                );
              }
            })}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Pagination
            count={count || 1}
            page={page}
            onChange={handleChangePage}
          />
        </Col>
      </Row>
    </>
  );
};

// const renderIntroManufacturer = (data) => (
//   <Row className="my-3 px-5" style={{ justifyContent: "center" }}>
//     <Card className="py-3 px-4">
//       <Col md="12">
//         <Row>
//           <Col md={4} className="font-weight-bold mb-2">
//             Số điện thoại
//           </Col>
//           <Col md={8} className="text-right mb-2">
//             {data.phone}
//           </Col>
//           <Col md={4} className="font-weight-bold mb-2">
//             Email
//           </Col>
//           <Col md={8} className="text-right mb-2">
//             {data.email}
//           </Col>
//           <Col md={4} className="font-weight-bold mb-2">
//             Website
//           </Col>
//           <Col md={8} className="text-right mb-2">
//             <a href={data.website} target="_blank" rel="noopener noreferrer">
//               {data.website}
//             </a>
//           </Col>
//           <Col md={4} className="font-weight-bold mb-2">
//             Địa chỉ
//           </Col>
//           <Col md={8} className="text-right mb-2">
//             {data.address}
//           </Col>
//         </Row>
//       </Col>
//       <hr />
//       <Col md="12">
//         {data.address ? (
//           <iframe
//             title="GoogleMap"
//             width="100%"
//             height="400"
//             frameBorder="0"
//             style={{ border: "0", padding: "15px" }}
//             src={`https://www.google.com/maps/embed/v1/place?key=${MY_API_KEY}&q=${data.address}`}
//             allowFullScreen
//           ></iframe>
//         ) : null}
//       </Col>
//     </Card>
//   </Row>
// );

const ManufacturerDetail = (props) => {
  // const theme = useTheme();
  // const [value, setValue] = useState(0);
  const uuid = props.match.params.id;
  const getManufacturerInfo = props.getManufacturerInfo;
  const manufacturerInfo = props.manufacturerInfo;
  const [info, setInfo] = useState({});

  useEffect(() => {
    getManufacturerInfo({ uuid });
  }, [getManufacturerInfo]);

  useEffect(() => {
    setInfo(manufacturerInfo);
  }, [manufacturerInfo]);

  const handleSeeDetail = (id) => [props.history.push("/q/" + id)];

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  return (
    <Container className="mt-3" style={{ minHeight: "560px" }}>
      <Row>
        <Col md={12}>
          {info.banner ? (
            <img
              style={{ width: "100%" }}
              src={info.banner.secure_url}
              alt="avatar"
            />
          ) : null}
        </Col>
        <Col md={12}>
          <CardShadow className="p-4">
            {info ? (
              <Row>
                <Col md={7} className="mb-2">
                  <h3 className="font-weight-bold">{info.name}</h3>
                  <p>
                    {info.dynamicDescription
                      ? stripHtml(info.dynamicDescription)
                      : ""}
                  </p>
                  <hr />
                  <Row>
                    <Col md={3} className="font-weight-bold mb-2">
                      Số điện thoại
                    </Col>
                    <Col md={9} className="text-right mb-2">
                      {info.phone}
                    </Col>
                    <Col md={3} className="font-weight-bold mb-2">
                      Email
                    </Col>
                    <Col md={9} className="text-right mb-2">
                      {info.email}
                    </Col>
                    <Col md={3} className="font-weight-bold mb-2">
                      Website
                    </Col>
                    <Col md={9} className="text-right mb-2">
                      <a
                        href={info.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {info.website}
                      </a>
                    </Col>
                    <Col md={3} className="font-weight-bold mb-2">
                      Địa chỉ
                    </Col>
                    <Col md={9} className="text-right mb-2">
                      {info.address}
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <img
                    style={{ width: "100%",
                    maxHeight: "320px",
                    objectFit: "cover" }}
                    src={
                      info.avatar && info.avatar.length
                        ? info.avatar[0].secure_url
                        : "https://res.cloudinary.com/agridential/image/upload/v1612235254/images/no_image_pbl5b1.png"
                    }
                    alt="avatar"
                  />
                </Col>
              </Row>
            ) : null}
          </CardShadow>
        </Col>
      </Row>
      {renderProductList({ ...props, handleSeeDetail })}
      {/* <CardShadow className="mt-2" position="static" color="default">
        <AntTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <AntTab label="Giới thiệu" icon={<CertIcon />} {...a11yProps(0)} />
          <AntTab
            label="Danh sách sản phẩm"
            icon={<SproutIcon />}
            {...a11yProps(1)}
          />
        </AntTabs>
      </CardShadow>
      <CardShadow
        className="py-3"
        style={{ overflow: "hidden !important" }}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {renderIntroManufacturer(info)}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {renderProductList({ ...props, handleSeeDetail })}
        </TabPanel>
      </CardShadow> */}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  productList: state.manufacturer.productList,
  manufacturerInfo: state.manufacturer.manufacturerInfo,
});
const mapDispatchToProps = (dispatch) => ({
  getProductList: (params) =>
    dispatch({ type: GET_PRODUCTS_BY_MANUFACTURER, params }),
  getManufacturerInfo: (params) =>
    dispatch({ type: GET_MANUFACTURER_INFO, params }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManufacturerDetail)
);
