import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getNewsByID, getOpInfoByID } from "actions/news";
import { getUserByID } from "actions/user";
import { cleanStore } from "actions/index";
import "moment/locale/vi";
import CalendarIcon from "mdi-react/CalendarIcon";
import AccountEditIcon from "mdi-react/AccountEditIcon";
import NewspaperIcon from "mdi-react/NewspaperVariantIcon";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import { stripHtml } from "../../../utils";
import Skeleton from "@material-ui/lab/Skeleton";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
moment.locale("vi");

const Post = (props) => {
  const { loading } = props;
  const [post, setPost] = useState([]);
  const [author, setAuthor] = useState("");
  const id = props.match.params.id;
  const path = props.location.pathname;

  useEffect(() => {
      if (path.indexOf("/tin-tuc") !== -1) {
        props.getNewsByID({ id });
      } else if (path.indexOf("/thong-tin-dieu-hanh") !== -1) {
        props.getOpInfoByID({ id });
      }

    return () => props.cleanStore();
  }, []);

  useEffect(() => {
    let data = {};
    if (path.indexOf("/tin-tuc") !== -1) {
      data = props.news;
    } else if (path.indexOf("/thong-tin-dieu-hanh") !== -1) {
      data = props.opInfo;
    }

    props.getUserByID({ id: data.author });
    setPost(data);
  }, [props.news, props.opInfo]);

  useEffect(() => {
    const data = props.author;
    if (data) {
      setAuthor(data.name);
    }
  }, [props.author]);

  return (
    <Container style={{minHeight: "560px"}}>
      <Row>
        {path.indexOf("/tin-tuc") !== -1 ? (
          <div className="w-100 border-new pb-2">
            <div className="bg-danger d-inline-block p-2 mt-3 ">
              <a href="/tin-tuc" className="text-white">
                {" "}
                <NewspaperIcon className="text-white mr-1" /> Tin tức nổi bật
              </a>
            </div>
            <a className="fl-right pr-3 mt-4 font-size-xs" href="/tin-tuc">Xem tất cả <ChevronRightIcon /></a>
          </div>
        ) : (
          <div className="w-100 border-new-event pb-2">
            <div className="bg-success d-inline-block p-2 mt-3 ">
              <a href="/thong-tin-dieu-hanh" className="text-white">
                {" "}
                <FileDocumentIcon className="text-white mr-1" /> Thông tin điều
                hành
              </a>
            </div>
            <a className="fl-right pr-3 mt-4 font-size-xs" href="/thong-tin-dieu-hanh">Xem tất cả <ChevronRightIcon /></a>
          </div>
        )}
      </Row>
      {loading ? (
        <>
          <Row className="mt-4">
            <Skeleton animation="ware" width="100%"/>
            <Skeleton animation="ware" width="40%"/>
          </Row>
          <Row className="mt-1 mb-1">
            <span className="author-text">
              <AccountEditIcon
                style={{ marginRight: "3px" }}
                color="#888"
                size={18}
              />
              <Skeleton animation="ware" />
            </span>

            <span className="time-text">
              <CalendarIcon
                style={{ marginRight: "3px" }}
                color="#888"
                size={18}
              />
              <Skeleton animation="ware" />
            </span>
          </Row>
          <hr />
          <Row
            className="mt-4 mb-5 detail-content"
            style={{ display: "block", textAlign: "center" }}
          >
            <Skeleton animation="ware" variant="text"/>
            <Skeleton animation="ware" variant="text"/>
            <Skeleton animation="ware" variant="rect" height={300} width="70%"/>
            <Skeleton animation="ware" variant="text"/>
            <Skeleton animation="ware" variant="text"/>
          </Row>
        </>
      ) : (
        <>
          <Row className="mt-5">
            {post.title && (
              <h3 className="detail-title">{stripHtml(post.title.rendered)}</h3>
            )}
          </Row>
          <Row className="mt-1 mb-1">
            <span className="author-text">
              <AccountEditIcon
                style={{ marginRight: "3px" }}
                color="#888"
                size={18}
              />
              {author || "Nguyễn Ngọc Quỳnh Như"}
            </span>

            <span className="time-text">
              <CalendarIcon
                style={{ marginRight: "3px" }}
                color="#888"
                size={18}
              />
              {moment(post.modified).format("dddd, DD/MM/YYYY HH:mm")}
            </span>
          </Row>
          <hr />
          <Row
            className="mt-4 mb-5 detail-content"
            style={{ display: "block" }}
          >
            {post.content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: post.content.rendered,
                }}
              />
            )}
          </Row>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  news: state.news.news,
  opInfo: state.news.opInfo,
  author: state.user.user,
  loading: state.news.loading,
});

const mapDispatchToProps = {
  getNewsByID,
  getUserByID,
  getOpInfoByID,
  cleanStore,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Post));
