import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import SearchIcon from "mdi-react/MagnifyIcon";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Collapse,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { connect } from "react-redux";
import { getContact } from "actions/contact";
import SupportContact from "../components/SupportContact";
// Hook

function handleActiveNav() {
  var current = window.location.pathname;
  $("nav ul li a").each(function () {
    var $this = $(this);
    // if the current path is like this link, make it active
    if (
      current.indexOf($this.attr("href")) !== -1 ||
      ((current === "/") &&
        $this.attr("href") === "/trang-chu")
    ) {
      $this.addClass("active");
    }
  });
}

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [search, setSearch] = React.useState("");

  const handleChange = (e) => {
    if (e.keyCode === 13) {
      // Cancel the default action, if needed
      e.preventDefault();
      // Trigger the button element with a click
      onSearch();
    }
    setSearch(e.target.value);
  };

  const onSearch = () => props.history.push("/q/" + search);
  useEffect(handleActiveNav, []);

  const contact = props.contact;
  const [infoContact, setInfo] = useState({
    logo: {},
  });

  useEffect(() => {
    setInfo(contact);
  }, [contact]);

  return (
    <>
      <div>
        <SupportContact />
        <Container className="header-container">
          <div className="row header-row-container bg-header-left">
            <div className="col-12">
              <div className="row">
                <div className="col-7" style={{ height: "100%" }}>
                  <div
                    className="row d-flex justify-content-center text-center"
                    style={{ height: "100%" }}
                  >
                    <div className="col-xl-6 col-lg-8 col-md-10 d-flex justify-content-center mt-2">
                      <a href="/">
                        <img
                          src={
                            infoContact.logo
                              ? infoContact.logo.secure_url
                              : "https://res.cloudinary.com/agridential/image/upload/v1602647664/Logo/image_x4dg6f.png"
                          }
                          alt="logoVBC"
                          style={{maxHeight: "60px"}}
                        />
                      </a>
                    </div>
                    <div className="col-md-2 col-sm-0 mt-3"></div>
                  </div>
                </div>
                <div
                  className="col-md-4 col-5 vertical-center bg-header-right right-header-container"
                  style={{ height: "100%", right: "0%" }}
                >
                  <div
                    className="left-logo"
                    style={{ maxWidth: "250px", minWidth: "140px", textAlign: "center" }}
                  >
                    <p
                      className="left-top-header-title"
                      style={{ color: "#0652DD", margin: 0, fontWeight: 800 }}
                    >
                      CHỢ ĐẦU MỐI
                    </p>
                    <p
                      className="left-bot-header-title"
                      style={{ color: "#EA2027", margin: 0, fontFamily: "Times New Roman", fontWeight: 800 }}
                    >
                      HÒA CƯỜNG
                    </p>
                  </div>
                  {/* <div className="mt-1" style={{ maxWidth: "250px" }}>
                    <p className="header-address">
                      Địa chỉ: 65 Lê Nổ Phường Hoà Cường Nam, Quận Hải Châu
                      Thành phố Đà Nẵng
                    </p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="row xy-center">
              <a href="/">
                <img
                  className="txng-logo"
                  src={
                    "https://res.cloudinary.com/dinhtq98/image/upload/v1606296093/Da%20Nang%20Wholesale%20Market/background_toj0lq.png"
                  }
                  alt="logoVBC"
                />
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-success py-2">
        <Container className="nav-container">
          <div className="d-flex justify-content-between content-center">
            <div className="d-flex justify-content-center align-items-center-nav" style={{width: "100%"}}>
              <Navbar style={{paddingLeft: "0"}} expand="lg">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="mr-auto" navbar>
                    <NavItem>
                      <NavLink href="/trang-chu" className="text-white">
                        Trang chủ
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/san-pham" className="text-white">
                        Sản phẩm
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/ho-kinh-doanh" className="text-white">
                        Hộ kinh doanh
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/tin-tuc" className="text-white">
                        Tin tức
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="/thong-tin-dieu-hanh"
                        className="text-white"
                      >
                        Thông tin điều hành
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/lien-he" className="text-white">
                        Liên hệ
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
              <div className={`row search-container ${isOpen ? "nav-group-btn-open" : ""}`}>
                <div className="col-12">
                  <div
                    className="search-small-scr"
                    // style={{ maxWidth: "230px" }}
                  >
                    <InputGroup>
                      <Input
                        placeholder="Mã sản phẩm"
                        onChange={handleChange}
                        onKeyUp={handleChange}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          className="btn-inline bg-light-green-1"
                          onClick={onSearch}
                        >
                          <SearchIcon />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                </div>
                {/* <div className={`col-5 ${isOpen ? "group-btn-right" : ""}`}>
                  <div
                    className={`search-small-scr`}
                    style={{ minWidth: "90px" }}
                  >
                    <a
                      title="Quản lý bài viết"
                      href="https://wp.danang.agridential.vn/wp-admin"
                      className="btn-inline mx-2"
                    >
                      <CommentEditIcon size={36} className="text-white" />
                    </a>
                    <a
                      title="Đăng nhập"
                      href="https://danang.agridential.vn"
                      className="btn-inline"
                    >
                      <LoginVariantIcon size={36} className="text-white" />
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  contact: state.contact.contact,
});
const mapDispatchToProps = {
  getContact,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
