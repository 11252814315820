import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
// import Footer from '../../components/Footer/footer.jsx';
import Footer from '../../../infoPage/components/Footer.js';
import styles from './infoStyles';
import Overview from './overview';

class LookupRice extends React.Component {
	constructor(props) {
		super(props);
		this.lookup_data = this.props.data;
		this.new = this.props.new;
		this.gln = this.props.lookup_gln;
		//this.uuid = this.props.data.cooperativeInfo.uuid;
		this.isSampleGLN = this.props.sample;
		this.intro = this.props.intro;
		//this.lookup_data.diary.reverse();
		this.cloud = false;
		this.garden = false;
		this.diary = [];
		this.distributor = [];
		this.total = 0;
		this.page = 0;
		this.state = {
			trial: false,
			banner: "",
			info: <div></div>
		};

	}

	componentDidMount() {
		if (this.props.overview) {
			if (this.props.overview.banner) {
				this.setState({
					banner: this.props.overview.banner
				})
			}
		}

	}
	componentWillReceiveProps(preProps) {
		if (preProps.overview !== this.props.overview) {
			this.setState({
				banner: preProps.overview.banner
			})
		}
	}
	render() {
		return (
			<div>
				{/* <GetLocation uuid={this.gln} /> */}
				<div className="contentparent">
					<div className="contentnopad" style={{ marginTop: '-20px' }}>
						<Overview setClick={click => this.clickOverview = click} />
					</div>
					
				</div>
				<div style={{ marginTop: '-18px' }}>
					<Footer />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		overview: state.overview
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
		getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
	}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LookupRice));
