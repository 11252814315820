import {
    GET_USER_LIST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILURE,
    GET_USER_BY_ID,
    GET_USER_BY_ID_SUCCESS,
    GET_USER_BY_ID_FAILURE
} from "actions/types"
const initialState = { userList: '', user: '', error: '' }
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_LIST:
            return {
                ...state
            }
        case GET_USER_LIST_SUCCESS:
            return {
                ...state,
                userList: action.payload
            }
        case GET_USER_LIST_FAILURE:
            return {
                ...state,
                error: action.error
            }
        case GET_USER_BY_ID:
            return {
                ...state
            }
        case GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                user: action.payload
            }
        case GET_USER_BY_ID_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}
export default reducer
