import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";

const Introduction = (props) => {
  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col md="12">
            <div className="w-100 pb-2">
              <div className="border-new-event d-inline-block py-1 mt-3 ">
                <span className="title-section font-weight-bold">GIỚI THIỆU CHỢ ĐẦU MỐI HOÀ CƯỜNG</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 py-3">
          <Col style={{minHeight: "300px"}} md="6">
            <iframe title="intro-video" width="100%" height="100%"
                src="https://www.youtube.com/embed/FUJFLJj0Ack">
            </iframe>
          </Col>

          <Col md="6">
            <div className="intro-content text-center">
                <span className="intro-title text-warning">Chợ Đầu Mối Hòa Cường</span>
                <ul className="mt-2 text-justify">
                    <li> <CheckCircleIcon className="text-success"/> Được xây dựng và đưa vào hoạt động từ năm 2005 và nằm trên địa bàn quản lý địa chính phường Hòa Cường Nam.</li>
                    <li> <CheckCircleIcon className="text-success"/> Là nơi phân phối sỉ mặt hàng chủ lực trái cây, rau hành lagim, có hơn 20 nhóm ngành hàng phục vụ cho khách sạn, nhà hàng, reort, bếp ăn tập thể, trường học và dân cư toàn bộ khu vực thành phố Đà Nẵng và các vùng lân cận.</li>
                </ul>
                <a className="btn bg-success text-white" href="/gioi-thieu-cho-hoa-cuong">Tìm hiểu thêm</a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(Introduction);
