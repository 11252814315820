import { combineReducers } from 'redux';
import diary from "./diary"
import overview from "./overview"
import distributor from "./distributor"
import manufacturer from "./manufacturer"
import certificate from "./certificate"
import map from "./map"
import productList from "./productList"
import news from './news'
import media from './media'
import user from './user'
import contact from './contact'
import advertisement from './advertisement'
import manufacturerList from './manufacturerList'
import AGDProductList from './AGDProductList'
const appReducer = combineReducers({
 diary,
 overview,
 distributor,
 manufacturer,
 certificate,
 map,
 productList,
 news,
 media,
 user,
 contact,
 advertisement,
 manufacturerList,
 AGDProductList
});


const rootReducer = (state, action) => {
  if (action.type === 'CLEAN_STORE') {
    state = undefined;
   // storage.removeItem('persist:root')
  }
  return appReducer(state, action);
};

export default rootReducer;