import {generateAction} from '../utils/index.js';
import {
    GET_CONTACT,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_FAILURE,
} from './types';

const getContact = generateAction(GET_CONTACT);

const getContactSuccess = generateAction(GET_CONTACT_SUCCESS);

const getContactFail = generateAction(GET_CONTACT_FAILURE);

export {
    getContact,
    getContactSuccess,
    getContactFail,
}

export default {
    getContact,
    getContactSuccess,
    getContactFail,
}