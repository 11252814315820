import {
    GET_AGD_PRODUCT_LIST,
    GET_AGD_PRODUCT_LIST_SUCCESS,
    GET_AGD_PRODUCT_LIST_FAILURE
} from "actions/types"
const initialState = { data: '' }
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AGD_PRODUCT_LIST:
            return {
                ...state
            }
        case GET_AGD_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                data: action.data
            }
        case GET_AGD_PRODUCT_LIST_FAILURE:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}
export default reducer
