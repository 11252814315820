import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { GET_AGD_PRODUCT_LIST } from "actions/types.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { truncate } from "../../../utils";

const ProductSlideShow = (props) => {
  const getAGDProductList = props.getAGDProductList;
  React.useEffect(() => {
    getAGDProductList();
  }, [getAGDProductList]);
  const AGDProductList = props.AGDProductList.data;
  const [listProduct, setList] = React.useState([]);
  React.useEffect(() => {
    if (AGDProductList) {
      setList(AGDProductList);
    }
  }, [AGDProductList]);

  const handleSeeDetail = (id) =>
    window.open("https://portal.agridential.vn/lookup/" + id);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col md="12">
            <div className="w-100 pb-2">
              <div className="border-product d-inline-block py-1 mt-3 ">
                <span className="title-section font-weight-bold">
                  SẢN PHẨM TIÊU BIỂU ĐÀ NẴNG
                </span>
              </div>
              <a
                className="fl-right pr-3 mt-4 font-size-xs"
                href="/san-pham-tieu-bieu-da-nang"
              >
                Xem tất cả <ChevronRightIcon />
              </a>
            </div>
          </Col>
        </Row>

        <Row className="py-4">
          <Col md="12">
            {(listProduct.length < 3 && window.innerWidth < 700) ||
            (listProduct.length < 4 && window.innerWidth < 1250) ||
            (listProduct.length < 5 && window.innerWidth > 1250) ? (
              <div className="d-flex flex-wrap-content flex-item-product">
                {listProduct.map((item, idx) => {
                  if (idx < 8) {
                    return (
                      <div
                        className="flex-stretch-card-product position-relative mb-2"
                        key={idx}
                      >
                        <Card className="no-transition w-100 card-product">
                          <div className="p-4 d-flex flex-column align-items-center card-container">
                            <div className="ava-product text-center">
                              <img
                                onClick={() =>
                                  handleSeeDetail(item.idToRetrieve[0].startId)
                                }
                                style={{ cursor: "pointer" }}
                                className="img-product rounded xy-center"
                                src={
                                  item.image &&
                                  item.image.length &&
                                  item.image[0] 
                                    ? item.image[0]
                                    : "https://res.cloudinary.com/agridential/image/upload/v1600922897/AGDImage/Asset_3_2x_yapsvy.png"
                                }
                                alt="product1"
                              />
                            </div>
                            <div className="info-product-card w-100">
                              <p
                                onClick={() =>
                                  handleSeeDetail(item.idToRetrieve[0].startId)
                                }
                                style={{ cursor: "pointer" }}
                                title={item.nameOfProduct}
                                className="font-weight-bold mt-3 mb-0 product-lable"
                              >
                                {truncate(item.nameOfProduct, 20)}
                              </p>
                              <p
                                className="origin-lable mb-0"
                                title={item.nameOfEnterprise}
                              >
                                {truncate(item.nameOfEnterprise, 25)}
                              </p>
                            </div>
                          </div>
                        </Card>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            ) : (
              <Slider {...settings}>
                {listProduct.map((item, idx) => {
                  if (idx < 8) {
                    return (
                      <div className="position-relative px-2" key={idx}>
                        <Card className="no-transition w-100 card-product">
                          <div className="p-4 d-flex flex-column align-items-center card-container">
                            <div className="ava-product text-center">
                              <img
                                onClick={() =>
                                  handleSeeDetail(item.idToRetrieve[0].startId)
                                }
                                style={{ cursor: "pointer" }}
                                className="img-product rounded xy-center"
                                src={
                                  item.image &&
                                  item.image.length &&
                                  item.image[0] &&
                                  item.image[0].secure_url
                                    ? item.image[0].secure_url
                                    : "https://res.cloudinary.com/agridential/image/upload/v1600922897/AGDImage/Asset_3_2x_yapsvy.png"
                                }
                                alt="product1"
                              />
                            </div>
                            <div className="info-product-card w-100">
                              <p
                                onClick={() =>
                                  handleSeeDetail(item.idToRetrieve[0].startId)
                                }
                                style={{ cursor: "pointer" }}
                                title={item.nameOfProduct}
                                className="font-weight-bold mt-3 mb-0 product-lable"
                              >
                                {truncate(item.nameOfProduct, 20)}
                              </p>
                              <p
                                className="origin-lable mb-0"
                                title={item.nameOfEnterprise}
                              >
                                {truncate(item.nameOfEnterprise, 25)}
                              </p>
                            </div>
                          </div>
                        </Card>
                      </div>
                    );
                  }
                  return null;
                })}
              </Slider>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  AGDProductList: state.AGDProductList,
});
const mapDispatchToProps = (dispatch) => ({
  getAGDProductList: () => dispatch({ type: GET_AGD_PRODUCT_LIST }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductSlideShow)
);
