const generateAction = (type) => {
  return (payload) => ({
    type,
    payload,
  });
};

const stripHtml = (html) =>
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

const truncate = (str, n) => {
  return (str && str.length > n) ? str.substr(0, n-1) + '...' : str;
}

export { generateAction, stripHtml, truncate };

export default { generateAction, stripHtml, truncate };
