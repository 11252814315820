import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from "moment";
import "react-perfect-scrollbar/dist/css/styles.css";
import  ChevronRightIcon from "mdi-react/ChevronRightIcon";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getNewsList, getOpInfoList } from "actions/news";
import { getMediaList } from "actions/media";
import { cleanStore } from "actions/index";
import { stripHtml, truncate } from "../../../utils";
import CalendarIcon from "mdi-react/CalendarIcon";
import Skeleton from "@material-ui/lab/Skeleton";

const News = (props) => {
  const [newsList, setNewsList] = useState([]);
  const [opInfoList, setDocumentList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  useEffect(() => {
    props.getNewsList({ page: 1, per_page: 10 });
    props.getOpInfoList({ page: 1, per_page: 4 });
    props.getMediaList();

    return () => props.cleanStore();
  }, []);

  useEffect(() => {
    const data = props.newsList || [];
    setNewsList(data);
  }, [props.newsList]);

  useEffect(() => {
    const data = props.opInfoList || [];
    setDocumentList(data);
  }, [props.opInfoList]);

  useEffect(() => {
    const mediaData = props.mediaList || [];
    if (mediaData.length && props.newsList && props.newsList.length) {
      const filteredMedia = props.newsList.map((ele, idx) => {
        return mediaData.find((o) => ele.featured_media === o.id) || {};
      });
      setMediaList(filteredMedia);
    }
  }, [props.mediaList, props.newsList]);

  // Check data being fetched from sever, if not, render Skeletons
  if (!newsList || !newsList.length) {
    return (
      <>
        <Container>
          <Row className="mt-3">
            <Col md="9">
              <div className="w-100 border-new pb-2">
                <div className="d-inline-block p-2 mt-3 ">
                  <span className="text-white">TIN TỨC NỔI BẬT</span>
                </div>
              </div>

              <Row className="mt-3">
                <Col md="7">
                  <div className="w-100">
                    <img
                      src={
                        "https://res.cloudinary.com/agridential/image/upload/v1612235254/images/no_image_pbl5b1.png"
                      }
                      alt="noImage"
                    />
                  </div>
                  <div className="w-100 mt-3 ">
                    <Skeleton animation="wave" />
                  </div>
                  <div className="w-100 mt-1" style={{ textAlign: "justify" }}>
                    <Skeleton animation="wave" />
                  </div>
                </Col>
                <Col md="5">
                  {[1, 2, 3, 4].map((element, idx) => {
                    if (idx > 0) {
                      return (
                        <div key={idx} className="d-flex flex-row mb-1">
                          <img
                            className="mx-2 sub-image"
                            src={
                              "https://res.cloudinary.com/agridential/image/upload/v1612235254/images/no_image_pbl5b1.png"
                            }
                            alt="noImage"
                          />
                          <div className="sub-content d-flex flex-column">
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                          </div>
                          <div className="divider"></div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </Col>
              </Row>
            </Col>
            <Col md="3">
              <div className="w-100 border-new-event pb-2">
                <div className="bg-success d-inline-block p-2 mt-3 ">
                  <span className="text-white">THÔNG TIN ĐIỀU HÀNH</span>
                </div>
              </div>
              <Row className="mt-3">
                <Col md="12">
                  {[1, 2, 3, 4].map((item, idx) => (
                    <>
                      <div
                        className="w-100 font-size-xs rounded bg-neutral-success"
                        key={idx}
                      >
                        <Skeleton animation="wave" />
                      </div>
                      <hr />
                    </>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="12">
              <Skeleton animation="wave" variant="rect" />
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col xl="9" lg="7" md="7">
            <div className="w-100 pb-2">
              <div className="d-inline-block border-new py-1 mt-3 ">
                <span className="title-section font-weight-bold">TIN TỨC NỔI BẬT</span>
              </div>
              <a className="fl-right pr-3 mt-4 font-size-xs" href="/tin-tuc">Xem tất cả <ChevronRightIcon /></a>
            </div>

            <Row className="mt-3">
              <Col xl="7" lg="12" md="12">
                <div className="w-100">
                  <img
                    src={
                      mediaList[0] && mediaList[0].source_url
                        ? mediaList[0].source_url
                        : "https://res.cloudinary.com/agridential/image/upload/v1612235254/images/no_image_pbl5b1.png"
                    }
                    alt="noImage"
                  />
                </div>
                <div className="w-100 mt-3 ">
                  <a
                    href={`/tin-tuc/${newsList[0].id}/${newsList[0].slug}`}
                    className="main-title"
                  >
                    {newsList[0].title ? truncate(stripHtml(newsList[0].title.rendered), 100) : ''}
                  </a>
                </div>
                <div className="w-100 mt-1" style={{ textAlign: "justify" }}>
                  <span className="main-short-description">
                    {newsList[0].excerpt ? truncate(stripHtml(newsList[0].excerpt.rendered), 200) : ""}
                  </span>
                </div>
              </Col>
              <Col xl="5" lg="12" md="12">
                {newsList.map((element, idx) => {
                  if (idx > 0 && idx < 5) {
                    return (
                      <>
                        <div key={idx} className="d-flex flex-row mb-1 py-1">
                          <img
                            className="mx-2 sub-image"
                            src={
                              mediaList[idx] && mediaList[idx].source_url
                                ? mediaList[idx].source_url
                                : "https://res.cloudinary.com/agridential/image/upload/v1612235254/images/no_image_pbl5b1.png"
                            }
                            alt="noImage"
                          />
                          <div className="sub-content d-flex flex-column">
                            <a
                              href={`/tin-tuc/${element.id}/${element.slug}`}
                              title={stripHtml(element.title.rendered)}
                              className="sub-title font-weight-bold font-size-xs"
                            >
                              {truncate(stripHtml(element.title.rendered), 60)}
                            </a>
                            <span className="time-text mt-1 mb-1">
                              <CalendarIcon
                                style={{ margin: "0 3px 3px 0" }}
                                size={16}
                              />
                              {moment(element.modified).format("DD/MM/YYYY")}
                            </span>
                          </div>
                          <div className="divider"></div>
                        </div>
                        <hr />
                      </>
                    );
                  }
                  return null;
                })}
              </Col>
            </Row>
          </Col>
          <Col xl="3" lg="5" md="5">
            <div className="w-100 pb-2">
              <div className="border-new-event d-inline-block py-1 mt-3 ">
                <span className="title-section font-weight-bold">THÔNG TIN ĐIỀU HÀNH</span>
              </div>
            </div>
            <Row className="mt-3">
              <Col md="12">
                {opInfoList &&
                  opInfoList.map((item, idx) => (
                    <>
                      <div
                        className="w-100 font-size-xs rounded bg-neutral-success"
                        key={idx}
                      >
                        <a
                          href={`/thong-tin-dieu-hanh/${item.id}/${item.slug}`}
                          title={stripHtml(item.title.rendered)}
                          className="sub-title font-size-xs"
                        >
                          {truncate(stripHtml(item.title.rendered), 130)}
                        </a>
                      </div>
                      <hr />
                    </>
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row className="mt-3">
          <Col md="12">
            <img
              src="https://res.cloudinary.com/agridential/image/upload/v1603445040/AGDImage/92_k7ydtu.png"
              alt="eventImg"
              style={{ width: "100%" }}
            />
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    newsList: state.news.newsList,
    mediaList: state.media.mediaList,
    opInfoList: state.news.opInfoList,
    loading: state.news.loading,
  };
};

const mapDispatchToProps = {
  getNewsList,
  getMediaList,
  getOpInfoList,
  cleanStore,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News));
