import {generateAction} from '../utils/index.js';
import {
    GET_NEWS_LIST,
    GET_NEWS_LIST_SUCCESS,
    GET_NEWS_LIST_FAILURE,
    GET_OPINFO_LIST, //OPINFO = operation information
    GET_OPINFO_LIST_SUCCESS,
    GET_OPINFO_LIST_FAILURE,
    GET_NEWS_BY_ID,
    GET_NEWS_BY_ID_SUCCESS,
    GET_NEWS_BY_ID_FAILURE,
    GET_OPINFO_BY_ID,
    GET_OPINFO_BY_ID_SUCCESS,
    GET_OPINFO_BY_ID_FAILURE,
} from './types';

const getNewsList = generateAction(GET_NEWS_LIST);

const getNewsListSuccess = generateAction(GET_NEWS_LIST_SUCCESS);

const getNewsListFail = generateAction(GET_NEWS_LIST_FAILURE);

const getOpInfoList = generateAction(GET_OPINFO_LIST);

const getOpInfoListSuccess = generateAction(GET_OPINFO_LIST_SUCCESS);

const getOpInfoListFail = generateAction(GET_OPINFO_LIST_FAILURE);

const getNewsByID = generateAction(GET_NEWS_BY_ID);

const getNewsByIDSuccess = generateAction(GET_NEWS_BY_ID_SUCCESS);

const getNewsByIDFail = generateAction(GET_NEWS_BY_ID_FAILURE);

const getOpInfoByID = generateAction(GET_OPINFO_BY_ID);

const getOpInfoByIDSuccess = generateAction(GET_OPINFO_BY_ID_SUCCESS);

const getOpInfoByIDFail = generateAction(GET_OPINFO_BY_ID_FAILURE);

export {
    getNewsList,
    getNewsListSuccess,
    getNewsListFail,
    getOpInfoList,
    getOpInfoListSuccess,
    getOpInfoListFail,
    getNewsByID,
    getNewsByIDSuccess,
    getNewsByIDFail,
    getOpInfoByID,
    getOpInfoByIDSuccess,
    getOpInfoByIDFail
}

export default {
    getNewsList,
    getNewsListSuccess,
    getNewsListFail,
    getOpInfoList,
    getOpInfoListSuccess,
    getOpInfoListFail,
    getNewsByID,
    getNewsByIDSuccess,
    getNewsByIDFail,
    getOpInfoByID,
    getOpInfoByIDSuccess,
    getOpInfoByIDFail
}


