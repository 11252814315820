import {
    GET_MANUFACTURER_LIST,
    GET_MANUFACTURER_LIST_SUCCESS,
    GET_MANUFACTURER_LIST_FAILURE,
} from "actions/types"
const initialState = { manufacturerList: '', error: '' }
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MANUFACTURER_LIST:
            return {
                ...state,
            };
        case GET_MANUFACTURER_LIST_SUCCESS:
            return {
                ...state,
                manufacturerList: action.payload
            };
        case GET_MANUFACTURER_LIST_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state;
    }
}
export default reducer;