import React from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import NewspaperIcon from "mdi-react/NewspaperVariantIcon";

const IntroDetail = (props) => {
  return (
    <Container style={{ minHeight: "560px" }}>
      <Row>
        <div className="w-100 border-new-event pb-2">
          <div className="bg-success d-inline-block p-2 mt-3 ">
            <div className="text-white">
              {" "}
              <NewspaperIcon className="text-white mr-1" /> Giới thiệu Chợ Đầu mối Hoà
              Cường
            </div>
          </div>
        </div>
      </Row>
      <>
        <Row className="mt-5">
          <Col className="col-12 text-center">
            <h3 className="intro-title text-warning">Chợ Đầu Mối Hòa Cường</h3>
          </Col>
        </Row>
        <Row className="mt-4 mb-5 detail-content" style={{ display: "block" }}>
          <p>
            {" "}
            Ban quản lý chợ Đầu Mối Hòa Cường trực thuộc Công ty Quản lý và phát
            triển các chợ Đà Nẵng.
          </p>

          <p>
            Trụ sở chính: 65 Lê Nổ, phường Hoà Cường Nam, quận Hải Châu, thành
            phố Đà Nẵng. Điện thoại liên hệ: 02363.644.215 và 02363.643.403.
          </p>

          <div className="text-center py-1">
            <img
              src="https://res.cloudinary.com/dinhtq98/image/upload/v1610075477/Da%20Nang%20Wholesale%20Market/DSCF3922_jbutml.jpg"
              alt="le-khai-truong-cho-dau-moi-hoa-cuong"
            />
            <p className="image-description">
              Lễ cắt băng khánh thành và đưa vào hoạt đông
            </p>
          </div>

          <ol className="orderred-list">
            <li>
              <b>Chức năng, nhiệm vụ:</b>
              <ul className="unorderred-list">
                <li>
                  Quản lý điều hành mọi hoạt động của chợ Đầu mối Hoà Cường.
                </li>
                <li>
                  Quản lý, khai thác cho thuê mặt bằng kinh doanh, mặt bằng
                  quảng cáo tại chợ: đẩy mạnh các hoạt động dịch vụ, tổ chức
                  chức chương trình sự kiện trưng bày giới thiệu quảng bá hàng
                  hoá, sản phẩm; tổ chức các nguồn thu theo quy định của Pháp
                  luật và của Công ty.
                </li>
                <li>
                  Phối hợp với cơ quan, đơn vị liên quan và chính quyền địa
                  phương làm tốt công tác quản lý thị trường, bảo đảm an ninh
                  trật tự an toàn xã hội, vệ sinh môi trường, văn minh thương
                  mại, phòng chống cháy nổ, thiên tai, an toàn thực phẩm, an
                  toàn công trình trong phạm vi chợ.
                </li>
                <li>
                  Tổ chức các hoạt động dịch vụ cung ứng cho tổ chức, cá nhân
                  kinh doanh trong chợ.
                </li>
                <li>
                  Hướng dẫn các tổ chức, cá nhân hoạt động kinh doanh dịch vụ
                  tại chợ và khách hàng chấp hành chủ trương, chính sách, pháp
                  luật của Nhà nước, nội quy quản lý chợ
                </li>
                <li>Lưu trữ hồ sơ, văn bản, hợp đồng theo quy định.</li>
                <li>
                  Thực hiện các nhiệm vụ khác do Ban Giám đốc Công ty giao.
                </li>
              </ul>
            </li>

            <li>
              <b>Cơ cấu tổ chức, bộ máy:</b>
              <ul className="unorderred-list">
                <li>Lãnh đạo Ban quản lý: Trưởng Ban; Phó Ban.</li>
                <li>Tổ Văn phòng: Bộ phận Kế hoạch; Bộ phận Kế toán.</li>
                <li>Tổ Kỹ thuật: Điện-nước; Xây dựng cơ bản.</li>
                <li>Tổ bảo vệ: 2 tổ bảo vệ hoạt động 24/24.</li>
              </ul>
            </li>

            <li>
              <b>Các tổ chức Đảng, Hội đoàn thể:</b>
              &nbsp;
              <span>
                Chi bộ; Công đoàn Bộ phận; Hội Liên hiệp phụ nữ cơ sở; Đoàn
                thanh niên; Phân Hội Cựu chiến binh.
              </span>

              <p>
                Ngoài ra, đơn vị còn quản lý 2 đội bốc xếp Trái cây và Rau hành
                – Lagim, đội xe thồ tự quản với trên 150 thành viên.
              </p>
              <p>
                Chợ Đầu Mối Hòa Cường được xây dựng và đưa vào hoạt động từ năm
                2005. Nằm trên địa bàn quản lý địa chính phường Hòa Cường Nam
                với các mặt tiền xung quanh đều tiếp giáp với 4 trục đường chính
                thông thoáng, độc lập (phía Bắc: giáp đường Hồ Nguyên Trừng,
                phía Nam: giáp đường Lê Nổ, phía Đông: giáp đường Lê Sát, phía
                Tây: giáp đường Lê Thanh Nghị); có diện tích đất: 20.000 m2.
              </p>
              <p>
                Chợ Đầu Mối Hòa Cường với số hộ kinh doanh trên 1.200 hộ là nơi
                phân phối Sỉ mặt hàng chủ lực trái cây, rau hành lagim, có hơn
                20 nhóm ngành hàng phục vụ cho khách sạn, nhà hàng, resort, bếp
                ăn tập thể, trường học và dân cư toàn bộ khu vực thành phố Đà
                Nẵng và các vùng lân cận. Lượng hàng hóa tập trung cao điểm và
                hoạt động giao dịch chính từ 01h00 – 7h00 sáng, với số lượng
                hàng hơn <strong>300</strong> tấn/ngày. Những ngày rằm, mùng một, lễ, tết lượng
                hàng về chợ trên 800-900 tấn/ngày.
              </p>
              <p>
                Lượng xe vận tải, container vận chuyển hàng hóa nhiều (từ 30 đến
                40 xe/ngày) và hàng trăm xe tải nhỏ vận chuyển phân phối hàng đi
                các nơi tiêu thụ.
              </p>
              <ul className="unorderred-list">
                <li>
                  Mặt hàng Trái cây: <b>160 - 170</b> tấn/ngày chủ yếu nhập về từ : Hà
                  Nội, Hưng Yên, Tây Ninh, Hà Giang, Ninh Thuận, Khánh Hòa, Phan
                  Rang, Phan Thiết, Đắc Lắc, Bến Tre, Tây Giang, Gia Lai, Đà
                  Lạt, thành phố Hồ Chí Minh,…
                </li>
                <li>
                  Mặt hàng Rau hành - lagim: <b>130 - 150</b> tấn/ngày chủ yếu từ các
                  tỉnh, thành: Hà Nội, Gia Lai, Đà Lạt, Bắc Ninh, thành phố Hồ
                  Chí Minh, Quảng Nam, Quảng Ngãi, Đắc Lắc, Quảng Trị, Hải
                  Dương, Bình Định, Tây Giang,…
                </li>
              </ul>
              <p>
                Với khẩu hiệu hành động "Phấn đấu vì sự an bình và phát triển
                bền vững". Trong quá trình hình thành và phát triển, Ban quản lý
                chợ Đầu mối Hoà Cường liên tục hoàn thành xuất sắc nhiệm vụ được
                giao hàng năm, đã được Bộ công Thương, UBND thành phố Đà Nẵng,
                Sở Công Thương thành phố Đà Nẵng, Công ty quản lý và phát triển
                chợ Đà Nẵng tặng Bằng khen, giấy khen, giấy chứng nhận.
              </p>
            </li>
          </ol>

          <div className="py-1">
            <iframe
              title="intro-video"
              allowfullscreen="true"
              style={{ minHeight: "400px" }}
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/FUJFLJj0Ack"
            ></iframe>
          </div>
        </Row>
      </>
    </Container>
  );
};

export default withRouter(IntroDetail);
