

const Sample = [
  {
    name: "Xoài cát chu cao lãnh",
    id: "0000000000000",
    corporation: "HTX Xoài Mỹ Xương",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572404937/TMDT_Sample/xoaicatchu_jr5qyl.png"
  },
  {
    name: "Xoài Sa Đéc",
    id: "0000000000012",
    corporation: "HTX Dịch Vụ Nông Nghiệp Tân Khánh Đông",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403543/TMDT_Sample/xoaisadec_xouoa6.jpg"
  },
  {
    name: "Gạo sạch tràng chim Đồng Tháp",
    id: "1000000000000",
    corporation: "Công ty lương thực Đồng Tháp",
    url: "https://res.cloudinary.com/agridential/image/upload/v1575865105/TMDT_Sample/gaodongthap_zsiylz.jpg"
  },
  // {
  //   name: "Cà chua bi vàng",
  //   id: "0000000000004",
  //   corporation: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Phong Thúy",
  //   url: "https://res.cloudinary.com/agridential/image/upload/v1575865122/TMDT_Sample/cachuabivang_k0ya26.png"
  // },
  // {
  //   name: "Cà chua cherry",
  //   id: "0000000000006",
  //   corporation: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Phong Thúy",
  //   url: "https://res.cloudinary.com/agridential/image/upload/v1575865133/TMDT_Sample/cachuacherry_ipmdry.png"
  // },
  // {
  //   name: "Cà chua cocktail",
  //   id: "0000000000007",
  //   corporation: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Phong Thúy",
  //   url: "https://res.cloudinary.com/agridential/image/upload/v1572403492/TMDT_Sample/cachuacocktail_t5qybl.png"
  // },
  {
    name: "Cà phê Mayaca",
    id: "0000001000000",
    corporation: "Công ty TNHH MTV Cà phê MAYACA",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403499/TMDT_Sample/caphemayaca_u9mpob.jpg"
  },
  {
    name: "Kim chi đông du",
    id: "0000000000010",
    corporation: "Công ty TNHH SX TM DV Đông Du Nguyễn",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403502/TMDT_Sample/kimchi_gsjwol.png"
  },
  {
    name: "Dưa lưới - Song Mai",
    id: "0000000000011",
    corporation: "Công ty TNHH Nông Nghiệp Song Mai",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403507/TMDT_Sample/dualuoi_zjfc06.jpg"
  },
  {
    name: "Nấm rơm Vinh Phúc",
    id: "0000000000014",
    corporation: "Vinh Phúc Farm",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403511/TMDT_Sample/namrom_zzydpv.jpg"
  },
  // {
  //   name: "Rau muống VietRAT",
  //   id: "0000000000008",
  //   corporation: "HTX Rau An Toàn Việt",
  //   url: "https://res.cloudinary.com/agridential/image/upload/v1572403513/TMDT_Sample/raumuong_mzcppm.png"
  // },
  {
    name: "Rượu Đẳng Sâm",
    id: "0000000020002",
    corporation: "HTX Nông - Lâm nghiệp - Thương mại & Dịch vụ Thanh Tâm",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403514/TMDT_Sample/ruousam_d1b4xx.png"
  },
  {
    name: "Bột nghệ hữu cơ Biophap",
    id: "0000000000015",
    corporation: "Công ty TNHH Biophap",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403521/TMDT_Sample/biophap_m7gdev.png"
  },
  {
    name: "Mật ong sạch Na Ni",
    id: "0000001000616",
    corporation: "Doanh nghiệp tư nhân Na Ni",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403535/TMDT_Sample/matongnani_tuucar.png"
  },
  {
    name: "Hũ nhân tỏi đen mật ong",
    id: "0000000000002",
    corporation: "Công ty Cổ phần Tư vấn Phát triển Thực phẩm và Dinh dưỡng",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403539/TMDT_Sample/toilinhdan_n766po.png"
  },
  {
    name: "Rau tần ô",
    id: "0000000000005",
    corporation: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Phong Thúy",
    url: "https://res.cloudinary.com/agridential/image/upload/v1572403541/TMDT_Sample/tungho_xbtcvr.jpg"
  },
  // {
  //   name: "Rau thơm hỗn hợp VietRAT",
  //   id: "0000000000009",
  //   corporation: "HTX Rau An Toàn Việt (VietRAT)",
  //   url: "https://res.cloudinary.com/agridential/image/upload/v1575865214/TMDT_Sample/rauthom_hc0nrh.png"
  // },
  {
    name: "Nụ trầm PL đặc biệt",
    id: "0000000000003",
    corporation: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Châu Thông",
    url: "https://res.cloudinary.com/agridential/image/upload/v1575865164/TMDT_Sample/tramhuongphuclinh_dgx7vz.png"
  }
]

export default Sample;
