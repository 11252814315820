import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import { FONT_SIZES, COLORS, FONTS } from '../../../../constants';
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import RaidoStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const styles =  theme => ({
  ...RaidoStyles,
  ...dashboardStyle,
  ...loginPageStyle,
  ...regularFormsStyle,
  ...sweetAlertStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "13px",
    textAlign: 'center',
    marginBottom: "3px",
    textDecoration: "none",
    whiteSpace: "nowrap",
  },
  detailtitle:{
    fontSize: "16px",
    fontFamily: "Muli",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  imageCert:{
    height: "90px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "45px",
    },
  },
  textCenter: {
    textAlign: 'center'
  },
  textGenuie:{
    display: "inline-block !important",
    padding: "5px",
    fontFamily: FONTS.PRIMARY,
    fontSize: "19px",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#4fb779",
   // marginBottom: '10px',
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "400"
    },
  },
  productName: {
    fontFamily: FONTS.PRIMARY,
    fontSize: "28px",
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: "25px",
    color: COLORS.BLACK,
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
      marginTop: "0px"
    },
  },
  paddingDate:{
    padding: "0px 5px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column"
  },
  paddingDateOne:{
    padding: "0px 5px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    borderRight: "1px solid #DFE3E8"
  },
  paddingDateThree:{
    padding: "0px 5px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    borderLeft: "1px solid #DFE3E8"
  },
  manufacName: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "18px",
    fontWeight: "400",
    textAlign: 'center',
    whiteSpace: "nowrap",
    backgroundColor: "#e5f3e5",
    borderRadius: "6px",
    color: "#4caf50",
    display: "inline-block",
    padding: "10px",
  },
  productAtt: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "17px",
    fontWeight: "300",
    textAlign: 'center',
    whiteSpace: "nowrap",
    paddingTop: "10px"
    // color: "#00bcd4",
  },
  content: {
    backgroundColor: "#F9F9F9",
    padding: "0px 0px !important"
  },
  contentfooter: {
    padding: "30px",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    fontFamily: FONTS.PRIMARY
  },
  logo: {
    width: "140px",
  },
  textLight: {
    color: COLORS.GRAY,
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      fontWeight: "light",
    },
  },
  textCode: {
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.NORMAL,
    fontStyle: "normal",
    letterSpacing: 0,
   // color: '#007ACE',
   color: "#212b36",
   fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
    marginTop: '5px',
    // marginBottom: '5px',
  },
  textSample:{
    display: "inline-block !important",
    padding: "5px",
    fontFamily: FONTS.PRIMARY,
    fontSize: "16px",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#212B36",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "400"
    },
  },
  iconhv:{
    height: "50px",
    width: "50px",
    backgroundColor: "#1e90ff",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "30px",
      width: "30px",
    },
  },
  cooInfo:{
    display: "flex",
    alignItems: 'start',
    flexFlow: "column",
  },
  imageLogo:{
    maxWidth: "400px",
    maxHeight: "400px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "250px",
      maxHeight: "200px"
    },
  },
  imgProduct: {
    textAlign: 'center',
    backgroundColor: "#F9F9F9",
    padding: "25px 25px !important",
    marginBottom: '20px'
  },
  btStatus: {
    backgroundColor: COLORS.RED_SOFT,
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "left",
    color: COLORS.RED,
    textTransform: 'none',
    marginRight: '30px',
  },
  btTrack: {
    backgroundColor: COLORS.GREEN_SOFT,
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "left",
    textTransform: 'none',
    color: COLORS.GREEN,
    "&:hover,&:focus": {
      outline: 0,
      boxShadow: "none!important",
    },
  },
  tag: {
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.LARGE,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#5a5a5a",
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  barLight: {
    width: '30%',
    borderTop: "2px solid #b7b7b7",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  barDark: {
    width: '25%',
    marginTop: "0px",
    borderTop: "2px solid #000",
  },
  barGreen: {
    width: '26vw',
    marginTop: "0px",
    borderTop: "2px solid #5cb360",
  },
  center: {
    textAlign: 'center',
  },
  noPadding: {
    padding: "0px 0px !important",
  },
  textBlock: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "600",
    fontStyle: "normal",
    letterSpacing: 0,
  },
  footerText: {

    color: "#000000",
    fontWeight: "600",
  },
  icon: {
    display: "inline", width: "40px", height: "40px"
  },
  contactstyle: {
    display: "inline", height: "40px", marginLeft: "10px", margin: "0 auto"
  },
  linebreak: {
    marginBottom: "5px",
    borderTop: "1px solid #b7b7b7"
  },
  textTx: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: FONTS.PRIMARY,
    fontSize: FONT_SIZES.SMALL,
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#007bff",
  },
  txTitle: {
    color: "#333333 !important",
    fontWeight: "400",
    margin: "10px 0px 0px"
  },
  notchedOutline: {
    borderColor: "#46494D",
    color: "#46494D",
  },
  multilineColor: {
    color: "#46494D",
  },
  timelineBadge: {
    color: "#FFFFFF",
    width: "50px",
    height: "50px",
    lineHeight: "51px",
    fontSize: "1.4em",
    textAlign: "center",
    position: "absolute",
    top: "16px",
    left: "50%",
    marginLeft: "-24px",
    zIndex: "100",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    borderBottomRightRadius: "50%",
    borderBottomLeftRadius: "50%"
  }, timelineSimpleBadge: {
    left: "5%"
  },
  badgeIcon: {
    width: "30px",
    height: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "15px",
    height: "15px",
    },
  },
  cooName:{
    color: "#007ACE",
    fontWeight: "600",
    fontFamily: "Muli",
    lineHeight: "20px",
    wordSpacing: "0px",
    cursor: "pointer",
    fontSize: "16px",
    margin: "0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    },
  },
  cooAddress:{
    color: "#8a8a8a",
    margin: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",

  },
  paddingEmoji:{
    height: "50px",
    width: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      width: "40px",
    },
  }
});
export default styles;