import {generateAction} from '../utils/index.js';
import {
    GET_ADS_LIST,
    GET_ADS_LIST_SUCCESS,
    GET_ADS_LIST_FAILURE,
} from './types';

const getAdsList = generateAction(GET_ADS_LIST);

const getAdsListSuccess = generateAction(GET_ADS_LIST_SUCCESS);

const getAdsListFail = generateAction(GET_ADS_LIST_FAILURE);

export {
    getAdsList,
    getAdsListSuccess,
    getAdsListFail,
}

export default {
    getAdsList,
    getAdsListSuccess,
    getAdsListFail,
}