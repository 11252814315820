const initialState = {
    data: "",
    sample: false,
    new: false,
    checkGarden: false,
    intro: "Loading",
    id: "",
    showId: "",
    gs1State: "",
    detailInfo: "",
    status: "",
    titleId: "",
    logo: [],
    date: [],
    images360: [],
    activation_date: "",
    checkGroupDetail: 0,
    banner: "",
    boxInfo: [],
    panorama: false,
    dynamicContent: "",
    expDate: "",
    color: "#4FB779"
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_OVERVIEW':
            return {
                ...state,
            };
        case 'GET_OVERVIEW_SUCCESS':
          
            return {
                ...state,
                data: action.data,
                sample: action.sample, // Check tem mẫu
                new: action.new, //Check product and Object
                checkGarden: action.checkGarden, //Check garden
                intro: action.intro, //Introduction
                id: action.id, //mã quét tem trong url
                showId: action.showId, //Mã show ra trong overview
                gs1State: action.gs1State, // Kiểm tra trạng thái gs1 để hiển thị tra cứu
                detailInfo: action.detailInfo, //Thông tin chi tiết
                status: action.status, //Trạng thái
                titleId: action.titleId,
                logo: action.logo,
                date: action.date,
                activation_date: action.activation_date,
                banner: action.banner,
                checkGroupDetail: action.checkGroupDetail, // kiểm trả phần xem chi tiết ở dạng object hay array để render theo group - bỏ trống or list theo danh sách,
                boxInfo: action.boxInfo, //Nếu type là box sẽ show thêm phần box info chứa thông tin của lô hàng đó,
                panorama: action.panorama, // Check xem nếu ảnh của sản phẩm là ảnh panoramat thì render ra ảnh xem dạng 360 độ
                dynamicContent: action.dynamicContent ,
                images360: action.images360, //Mảng chứa ảnh 360 độ nếu có,
                expDate: action.expDate, //Ngày sản xuất,
                color: action.color ? action.color : '#4FB779' //Mã màu sắc do người dùng thiết lập trên webapp trong page profile => Thiết lập
            };
        case 'GET_OVERVIEW_FAILURE':
          
            return {
                ...state,
                intro: null
            }
        default:
            return state;
    }
}
export default reducer;