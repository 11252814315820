/* eslint-disable jsx-a11y/anchor-is-valid */
import { hot } from 'react-hot-loader/root';
import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import LookupPageV2 from './layouts/lookupPagev2';
import ScanPage from './layouts/ScanPage';
import Homepage from './layouts/lookupPagev2/pages/Ecommerce/index.jsx'
import {store }from 'store/store.js'
import { withRouter } from 'react-router-dom'
import InfoPage from "./layouts/infoPage/index"
import DetailInfoPage from './layouts/infoPage/detailInfo'
import NewsListPage from './layouts/infoPage/newsListPage'
import ProductListPage from './layouts/infoPage/productListPage'
import ManufacturerListPage from './layouts/infoPage/manufacturerListPage'
import ManufacturerDetailPage from './layouts/infoPage/manufacturerDetailPage'
import ContactPage from './layouts/infoPage/contactPage'
import IntroDetailPage from './layouts/infoPage/introDetailPage'
import ClearCache from 'react-clear-cache';
import GridItem from "components/Grid/GridItem.jsx";

//import { PersistGate } from 'redux-persist/lib/integration/react';
class App extends React.Component {
  render() {
    return (
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) => {
          return (
            <Provider store={store}>
              <Router>
                <Switch>
                  <Route exact path="/scan" component={withRouter(ScanPage)} />
                  <Route exact path="/home" component={withRouter(Homepage)} />
                  <Route exact path="/q/:id" component={withRouter(LookupPageV2)} />
                  <Route exact path="/profile/:id" component={withRouter(LookupPageV2)} />
                  <Route exact path="/certificate/:id" component={withRouter(LookupPageV2)} />
                  <Route exact path="/general/:id" component={withRouter(LookupPageV2)} />
                  <Route exact path="/" component={withRouter(InfoPage)} />
                  <Route exact path="/trang-chu" component={withRouter(InfoPage)} />
                  <Route exact path="/:category/:id/:slug" component={withRouter(DetailInfoPage)} />
                  <Route exact path="/san-pham" component={withRouter(ProductListPage)} />
                  <Route exact path="/san-pham-tieu-bieu-da-nang" component={withRouter(ProductListPage)} />
                  <Route exact path="/ho-kinh-doanh" component={withRouter(ManufacturerListPage)} />
                  <Route exact path="/ho-kinh-doanh/:id" component={withRouter(ManufacturerDetailPage)} />
                  <Route exact path="/tin-tuc" component={withRouter(NewsListPage)} />
                  <Route exact path="/thong-tin-dieu-hanh" component={withRouter(NewsListPage)} />
                  <Route exact path="/lien-he" component={withRouter(ContactPage)} />
                  <Route exact path="/gioi-thieu-cho-hoa-cuong" component={withRouter(IntroDetailPage)}/>
                  <Route path="/q/:id" component={withRouter(LookupPageV2)} />
                  <Route path='/' component={withRouter(InfoPage)} />
                </Switch>
              </Router>
            </Provider>
          )
        }}
      </ClearCache>
    )
  }
}
App.propTypes = {
  store: PropTypes.object.isRequired
}

export default hot(App);