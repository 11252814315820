import {generateAction} from '../utils/index.js';
import {
    GET_MEDIA_LIST,
    GET_MEDIA_LIST_SUCCESS,
    GET_MEDIA_LIST_FAILURE,
    GET_MEDIA_BY_ID,
    GET_MEDIA_BY_ID_SUCCESS,
    GET_MEDIA_BY_ID_FAILURE,
} from './types';

const getMediaList = generateAction(GET_MEDIA_LIST);

const getMediaListSuccess = generateAction(GET_MEDIA_LIST_SUCCESS);

const getMediaListFail = generateAction(GET_MEDIA_LIST_FAILURE);

const getMediaByID = generateAction(GET_MEDIA_BY_ID);

const getMediaByIDSuccess = generateAction(GET_MEDIA_BY_ID_SUCCESS);

const getMediaByIDFail = generateAction(GET_MEDIA_BY_ID_FAILURE);

export {
    getMediaList,
    getMediaListSuccess,
    getMediaListFail,
    getMediaByID,
    getMediaByIDSuccess,
    getMediaByIDFail,
}

export default {
    getMediaList,
    getMediaListSuccess,
    getMediaListFail,
    getMediaByID,
    getMediaByIDSuccess,
    getMediaByIDFail,
}


