import React from "react";
import { connect } from "react-redux";

SupportContact.propTypes = {};

function TriggerOutlook(email) {
  var body = "";

  var subject = "GÓP Ý HỆ THỐNG TRUY XUẤT NGUỒN GỐC";

  window.location.href = `mailto:${email}?body=` + body + "&subject=" + subject;
}

function SupportContact(props) {
  const contact = props.contact;
  const [contactInfo, setContact] = React.useState({});

  React.useEffect(() => {
      if (contact) {
        setContact(contact);
      }
  }, [contact]);

  if (!(contactInfo && contactInfo.email)) {
    return null;
  }

  return (
    <div className="fixed-support-contact text-center">
      <div className="support-contact-container">
        <a onClick={() => TriggerOutlook(contactInfo.email)}>
          <img
            style={{ width: "100%" }}
            className="xy-center"
            src="https://res.cloudinary.com/dinhtq98/image/upload/v1606193683/Da%20Nang%20Wholesale%20Market/email_4_hhnusz.png"
            alt="support-contect"
          />
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  contact: state.contact.contact,
});

export default connect(mapStateToProps)(SupportContact);
