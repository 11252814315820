import { withStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import strings from 'constants/strings';
import UpIcon from "mdi-react/AppleKeyboardControlIcon";
import CertIcon from "mdi-react/TicketIcon";
import ProfileIcon from "mdi-react/ImageFilterHdrIcon";
import React from 'react';
import { connect } from 'react-redux';
import { Events, scroller } from 'react-scroll';
// import Footer from '../../components/Footer/footer.jsx';
import Footer from '../../../infoPage/components/Footer.js';
import Certificate from "./certificates";
import Distributor from "./distributor";
import styles from './infoStyles';
import Journey from './journey';
import Manufacturer from './manufacturer';
import Overview from './overview';
class LookupRice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			trial: false,
			banner: "",
			activeTab: 1,
			scrollTo: "none",
			info: <div></div>
		};
		this.handleActive = this.handleActive.bind(this);
		this.scrollToWithContainer = this.scrollToWithContainer.bind(this);
		this.handleActiveScroll = this.handleActiveScroll.bind(this);
		this.handleOnlyActiveTab = this.handleOnlyActiveTab.bind(this);
	}

	scrollToWithContainer(name) {

		new Promise((resolve, reject) => {

			Events.scrollEvent.register('end', () => {
				resolve();
				Events.scrollEvent.remove('end');
			});

			scroller.scrollTo(name, {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuart'
			});

		});
	}
	handleActiveScroll() {
		var y_position = window.pageYOffset;
		var info_content = document.getElementById('info').offsetTop;
		if (y_position > info_content) {
			
			this.setState({
				scrollTop: ""
			})
		}
		if (y_position < info_content) {
			this.setState({
				scrollTop: "none"
			})
		}
	}
	handleOnlyActiveTab(id) {
		this.setState({
			activeTab: id
		})
	}
	handleActive = (id, name) => {
		if(name === "manufacturer"){
			this.setState({
				activeTab: id,
				info: <Manufacturer/>
			})
		}
		
		if(name === "diary"){
			this.setState({
				activeTab: id,
				info: <Journey/>
			})
		}
		if(name === "distributor"){
			this.setState({
				activeTab: id,
				info: <Distributor/>
			})
		}
		if(name === "certificate"){
			this.setState({
				activeTab: id,
				info: <Certificate/>
			})
		}
		this.scrollToWithContainer("info")
	}
	componentDidMount() {
		if (this.props.overview) {
			if (this.props.overview.banner) {
				this.setState({
					banner: this.props.overview.banner
				})
			}
		}
		this.setState({
			info: (
				<Manufacturer setClick={click => this.clickManufacturer = click} />
			)
		});
		Events.scrollEvent.register('begin', function () {
			//console.log("begin", arguments);
		});

		Events.scrollEvent.register('end', function () {
			//console.log("end", arguments);
		});
		window.addEventListener('scroll', this.handleActiveScroll);
	}
	componentWillReceiveProps(preProps) {
		if (preProps.overview !== this.props.overview) {
			this.setState({
				banner: preProps.overview.banner
			})
		}
	}
	render() {
		const { classes } = this.props;
		return (
			<div>
				<div className={classes.toTop} style={{display: this.state.scrollTop}}onClick={() => this.scrollToWithContainer('topContent')}>
					<UpIcon size={30} style={{ fill: "#fff" }} />
				</div>
				<div className="contentparent" id="topContent">
					{this.state.banner ? <div className="contentnopad">
						<img alt="banner" src={this.state.banner} style={{ width: '100%' }}></img>
					</div> : null}
					<div className="contentnopad" style={{ marginTop: '-20px' }}>
						<Overview setClick={click => this.clickOverview = click} />
					</div>
					<div id="info" className="contentnopad" style={{ marginTop: '-50px' }}>
						<Card>
							{this.state.info}
						</Card>

					</div>
					<div className="contentnopadbar">
						<Card>
							<div >
								<GridContainer justify="center">
									<GridItem xs={6} sm={6} md={6} lg={6} className={classes.centerIcon} onClick={() => this.handleActive(1, 'manufacturer')}>
										<div className={this.state.activeTab === 1 ? classes.cardIconActive : classes.cardIcon}>
											<ProfileIcon size={30} style={this.state.activeTab === 1 ? { fill: "#ff9f43" } : { fill: "rgb(134, 142, 150)" }} />
											<p className={this.state.activeTab === 1 ? classes.textIconActive : classes.textIcon}>{strings.unit}</p>
										</div>
									</GridItem>
									<GridItem xs={6} sm={6} md={6} lg={6} className={classes.centerIcon} onClick={() => this.handleActive(2, 'diary')}>
										<div className={this.state.activeTab === 2 ? classes.cardIconActive : classes.cardIcon}>
											<CertIcon size={30} style={this.state.activeTab === 2 ? { fill: "#ff9f43" } : { fill: "rgb(134, 142, 150)" }} />
											<p className={this.state.activeTab === 2 ? classes.textIconActive : classes.textIcon}>{strings.actions}</p>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</Card>
					</div>
				</div>
				<div style={{ marginTop: '-18px' }}>
					<Footer />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		overview: state.overview
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
		getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
	}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LookupRice));
