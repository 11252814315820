import React from "react";
// import { Slide } from "react-slideshow-image";
import { Row, Col, Container } from "reactstrap";
import "../css/index.css";
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/src/core/styles.scss';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import "../css/override.scss";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const AdsSlideShow = (props) => {
  const { data, show } = props;
  if (!data || !data.length) return null;
  return (
    <>
      {show ? (
        <Container className="ads-container mt-2">
          <Row className="mt-3">
            <Col md="12">
              {data.length > 1 ? (
                <AutoplaySlider
                  play={true}
                  cancelOnInteraction={false}
                  interval={4000}
                  cssModule={AwsSliderStyles}
                >
                  {data.map((item, idx) => {
                    if (item.link) {
                      return (
                        <div key={idx} style={{ width: "100%" }}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={item.link}
                          >
                            <img
                              src={item.image.secure_url}
                              alt="eventImg"
                              style={{ objectFit: "cover", width: "100%" }}
                              //   style={{ width: "100%" }}
                            />
                          </a>
                        </div>
                      );
                    } else {
                      return (
                        <div key={idx} style={{ width: "100%" }}>
                          <img
                            src={item.image.secure_url}
                            alt="eventImg"
                            // style={{ width: "100%" }}
                          />
                        </div>
                      );
                    }
                  })}
                </AutoplaySlider>
              ) : data[0].link ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data[0].link}
                >
                  <img
                    src={data[0].image.secure_url}
                    alt="eventImg"
                    style={{ width: "100%" }}
                  />
                </a>
              ) : (
                <img
                  src={data[0].image.secure_url}
                  alt="eventImg"
                  style={{ width: "100%" }}
                />
              )}
            </Col>
          </Row>
        </Container>
      ) : null}
    </>
  );
};

export default AdsSlideShow;
