import {generateAction} from '../utils/index.js';
import {
    CLEAN_STORE
} from './types';

const cleanStore = generateAction(CLEAN_STORE);

export {
    cleanStore,
}

export default {
    cleanStore,
}


