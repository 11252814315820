import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { GET_PRODUCT_LIST } from "actions/types.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SproutIcon from "mdi-react/SproutIcon";
import { Pagination } from "@material-ui/lab";
import { truncate } from "../../../utils";
const Products = (props) => {
  const getProductList = props.getProductList;
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  
  React.useEffect(() => {
    getProductList(page, 8);
  }, [getProductList]);

  const productList = props.productList;
  const [listProduct, setList] = React.useState([]);

  React.useEffect(() => {
    if (productList) {
      setList(productList);

      const total = productList.length
      if (total) {
        const numberPage = Math.ceil(total / 8);
        setCount(numberPage);
      }
    }
  }, [productList]);
  const handleSeeDetail = (id) => [props.history.push("/q/" + id)];

  const handleSeeOrigin = (id) => [props.history.push("/ho-kinh-doanh/" + id)];

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  return (
    <>
      <Container style={{minHeight: "560px"}}>
        <Row>
          <Col md="12">
            <div className="w-100 border-product pb-2">
              <div className="bg-warning d-inline-block p-2 mt-3 ">
                <span className="text-white">
                  <SproutIcon className="text-white mr-1" /> Danh sách sản phẩm
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="mt-4 d-flex flex-wrap-content flex-item-product">
              {listProduct.map((item, idx) => {
                if (idx >= (page - 1)*8 && idx < page*8) {
                  return (
                    <div
                      className="flex-stretch-card-product position-relative mb-2"
                      key={idx}
                    >
                      <Card className="no-transition w-100 card-product">
                        <div
                          className="p-4 d-flex flex-column align-items-center card-container"
                        >
                          <div className="ava-product text-center">
                            <img
                              onClick={() =>
                                handleSeeDetail(item.idToRetrieve[0].startId)
                              }
                              className="img-product rounded xy-center"
                              src={
                                item.image && item.image.length && item.image[0] && item.image[0].secure_url
                                  ? item.image[0].secure_url
                                  : "https://res.cloudinary.com/agridential/image/upload/v1600922897/AGDImage/Asset_3_2x_yapsvy.png"
                              }
                              alt="product1"
                            />
                          </div>
                          <div className="info-product-card w-100">
                            <p
                              onClick={() =>
                                handleSeeDetail(item.idToRetrieve[0].startId)
                              }
                              title={item.nameOfProduct}
                              className="font-weight-bold mt-3 mb-0 product-lable"
                            >
                              {truncate(item.nameOfProduct, 25)}
                            </p>
                            <p
                              onClick={() => handleSeeOrigin(item.ownedBy)}
                              className="origin-lable mb-0"
                              title={item.nameOfEnterprise}
                            >
                              {truncate(item.nameOfEnterprise, 25)}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                }
              })}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <Pagination
              count={count || 1}
              page={page}
              onChange={handleChangePage}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  productList: state.productList.data,
  total: state.productList.total,
});
const mapDispatchToProps = (dispatch) => ({
  getProductList: () => dispatch({ type: GET_PRODUCT_LIST }),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Products)
);
