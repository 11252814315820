import {
  GET_NEWS_LIST,
  GET_NEWS_LIST_SUCCESS,
  GET_NEWS_LIST_FAILURE,
  GET_OPINFO_LIST,
  GET_OPINFO_LIST_SUCCESS,
  GET_OPINFO_LIST_FAILURE,
  GET_NEWS_BY_ID,
  GET_NEWS_BY_ID_SUCCESS,
  GET_NEWS_BY_ID_FAILURE,
  GET_OPINFO_BY_ID,
  GET_OPINFO_BY_ID_SUCCESS,
  GET_OPINFO_BY_ID_FAILURE,
} from "actions/types";
const initialState = {
  opInfoList: "",
  newsList: "",
  news: "",
  opInfo: "",
  loading: true,
  error: "",
  total: "",
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWS_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_NEWS_LIST_SUCCESS:
      return {
        ...state,
        newsList: action.payload.data,
        total: action.payload.totalData,
        loading: false,
      };
    case GET_NEWS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case GET_OPINFO_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_OPINFO_LIST_SUCCESS:
      return {
        ...state,
        opInfoList: action.payload.data,
        total: action.payload.totalData,
        loading: false,
      };
    case GET_OPINFO_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case GET_NEWS_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        news: action.payload,
        loading: false,
      };
    case GET_NEWS_BY_ID_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    case GET_OPINFO_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_OPINFO_BY_ID_SUCCESS:
      return {
        ...state,
        opInfo: action.payload,
        loading: false,
      };
    case GET_OPINFO_BY_ID_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    default:
      return state;
  }
};
export default reducer;
