import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAdsList } from "actions/advertisement";

function handleScroll() {
  if (document.getElementById("ad-left")) {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollTop > 200) {
      document.getElementById("ad-left").classList.add("over-navigation");
    } else {
      document.getElementById("ad-left").classList.remove("over-navigation");
    }

    if (scrollHeight - scrollTop < 750) {
      document.getElementById("ad-left").classList.add("over-footer");
    } else {
      document.getElementById("ad-left").classList.remove("over-footer");
    }
  }

  if (document.getElementById("ad-right")) {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollTop > 200) {
      document.getElementById("ad-right").classList.add("over-navigation");
    } else {
      document.getElementById("ad-right").classList.remove("over-navigation");
    }

    if (scrollHeight - scrollTop < 750) {
      document.getElementById("ad-right").classList.add("over-footer");
    } else {
      document.getElementById("ad-right").classList.remove("over-footer");
    }
  }
}

const Advertisements = (props) => {
  const [adsList, setAdsList] = useState([]);
  const getAdsList = props.getAdsList;
  useEffect(() => {
    getAdsList();
  }, [getAdsList]);

  useEffect(() => {
    const temp = props.adsList;
    setAdsList(temp);
  }, [props.adsList]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  if (!adsList || !adsList.length) {
    return null;
  }

  return (
    <>
      {adsList[0].show && adsList[0].images[0] ? (
        <div id="ad-left" className="fixed-ad-side-left">
          {adsList[0].images[0].link ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={adsList[0].images[0].link}
            >
              <img alt="logo" src={adsList[0].images[0].image.secure_url} />
            </a>
          ) : (
            <img alt="logo" src={adsList[0].images[0].image.secure_url} />
          )}
        </div>
      ) : null}
      {adsList[2] && adsList[2].show && adsList[2].images[0] ? (
        <div id="ad-right" className="fixed-ad-side-right">
          {adsList[2].images[0].link ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={adsList[2].images[0].link}
            >
              <img alt="logo" src={adsList[2].images[0].image.secure_url} />
            </a>
          ) : (
            <img alt="logo" src={adsList[2].images[0].image.secure_url} />
          )}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  adsList: state.advertisement.adsList,
});
const mapDispatchToProps = {
  getAdsList,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Advertisements)
);
