import { withStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import strings from 'constants/strings';
import React from 'react';
import { connect } from 'react-redux';
import { Events, scroller } from 'react-scroll';
// import Footer from '../../components/Footer/footer.jsx';
import Footer from '../../../infoPage/components/Footer.js';
import styles from './infoStyles';
import Journey from './journey';
import Manufacturer from './manufacturer';
import Overview from './overview';
import ProfileIcon from "mdi-react/MapMarkerIcon"
import UpIcon from "mdi-react/AppleKeyboardControlIcon"
import TruckIcon from "mdi-react/TruckIcon"
import TreeIcon from "mdi-react/TreeIcon"
import CertIcon from "mdi-react/InformationIcon"
import Distributor from "./distributor"
import InfoDetail from "./introduce"
import Advertisements from '../../../infoPage/components/Advertisements.js'
import "./css/animationSwing.css"

function myFunction() {
	var navbar = document.getElementById("navBar");
	if (navbar) {
		var sticky = navbar.offsetTop;
		if (window.pageYOffset >= sticky) {
			navbar.classList.add("sticky")
		} else {
			navbar.classList.remove("sticky");
		}
	}
}

class LookupRice extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			trial: false,
			banner: "",
			activeTab: 2,
			scrollTo: "none",
			info: <div></div>,
			type: "",
			color: '#4FB779'
		};
		this.handleActive = this.handleActive.bind(this);
		this.scrollToWithContainer = this.scrollToWithContainer.bind(this);
		this.handleActiveScroll = this.handleActiveScroll.bind(this);
		this.handleOnlyActiveTab = this.handleOnlyActiveTab.bind(this);
		this.clickOverview = this.clickOverview.bind(this)
	}

	scrollToWithContainer(name) {
		new Promise((resolve, reject) => {

			Events.scrollEvent.register('end', () => {
				resolve();
				Events.scrollEvent.remove('end');
			});

			scroller.scrollTo(name, {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuart'
			});

		});
	}
	async clickOverview() {
		await this.setState({
			activeTab: 1,
			info: <Manufacturer />
		})
		this.scrollToWithContainer("info")
	}
	handleActiveScroll() {
		var y_position = window.pageYOffset;
		if (document.getElementById('info')) {
			var info_content = document.getElementById('info').offsetTop;
			if (y_position > info_content) {

				this.setState({
					scrollTop: ""
				})
			}
			if (y_position < info_content) {
				this.setState({
					scrollTop: "none"
				})
			}
		}

	}
	handleOnlyActiveTab(id) {
		this.setState({
			activeTab: id
		})
	}

	handleActive = (id, name) => {
		if (name === "manufacturer") {
			this.setState({
				activeTab: id,
				info: <Manufacturer />
			})
		}

		if (name === "diary") {
			this.setState({
				activeTab: id,
				info: <Journey />
			})
		}
		if (name === "distributor") {
			this.setState({
				activeTab: id,
				info: <Distributor />
			})
		}
		if (name === "certificate") {
			this.setState({
				activeTab: id,
				info: <InfoDetail />
			})
		}
		this.scrollToWithContainer("info")
	}
	componentDidMount() {

		if (
			this.props.overview &&
			this.props.overview.data &&
			this.props.overview.data.type === "PRODUCTTYPE"
		) {
			this.handleActive(4, 'certificate')
		}
		else {
			this.setState({
				info: (
					<Journey />
				)
			});
		}

		if (
			this.props.overview &&
			this.props.overview.data &&
			this.props.overview.data.productInfo &&
			this.props.overview.data.productInfo.showOrigin &&
			this.props.overview.data.productInfo.origin
		) {
			this.setState({
				banner: this.props.overview.data.productInfo.origin.banner[0].secure_url
			})
		}
		else {
			if (this.props.overview) {
				if (this.props.overview.banner) {
					this.setState({
						banner: this.props.overview.banner
					})
				}
			}
			if (this.props.overview.data) {
				if (this.props.overview.data.type) {
					this.setState({
						type: this.props.overview.data.type
					})
				}
				if (this.props.overview.color) {
					this.setState({
						color: this.props.overview.color
					})
				}
			}
		}


		Events.scrollEvent.register('begin', function () {
			//console.log("begin", arguments);
		});

		Events.scrollEvent.register('end', function () {
			//console.log("end", arguments);
		});
		window.addEventListener('scroll', this.handleActiveScroll);

		//sticky navbar
		window.onscroll = function () { myFunction() }
	}
	componentDidUpdate(preProps) {
		if (preProps.overview !== this.props.overview) {
			if (
				preProps.overview &&
				preProps.overview.data &&
				preProps.overview.data.productInfo &&
				preProps.overview.data.productInfo.showOrigin &&
				preProps.overview.data.productInfo.origin
			) {
				this.setState({
					banner: preProps.overview.data.productInfo.origin.banner
				})
			}
			else {
				if (preProps.overview) {
					if (preProps.overview.banner) {
						this.setState({
							banner: preProps.overview.banner
						})
					}
				}
			}
			if(preProps.overview){
				if(preProps.overview.color){
					this.setState({
						color: preProps.overview.color
					})
				}
			}

		}
	}
	render() {
		const { classes } = this.props;
		return (
			<div>
				<Advertisements />
				<div className={classes.toTop} style={{ display: this.state.scrollTop }} onClick={() => this.scrollToWithContainer('topContent')}>
					<UpIcon size={30} style={{ fill: "#fff" }} />
				</div>
				<div className="contentparent pt-3" id="topContent">
					{this.state.banner ? <div className="contentnopad">
						<img alt="banner" src={this.state.banner} style={{ width: '100%' }}></img>
					</div> : null}
					<div className="contentnopad" style={{ marginTop: '-20px' }}>
						<Overview scrollToMa={this.clickOverview} />
					</div>
					<div id="info">
					</div>
					<div className="contentnopadbar" id="navBar">
						<Card>
							<div className={classes.centerBar}>
								<GridContainer className={classes.botBar}>
									<GridItem 
									// xs={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4} 
									// sm={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4} 
									// md={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4} 
									// lg={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4}
									xs={4}
									sm={4}
									md={4}
									className={classes.centerIcon} 
									onClick={() => this.handleActive(4, 'certificate')}>
										<div className={this.state.activeTab === 4 ? classes.cardIconActive : classes.cardIcon}  style={{backgroundColor: this.state.activeTab === 4 ? this.state.color + '12' : '#fff', borderTop: this.state.activeTab === 4 ? '3px solid' + this.state.color : 'none'}}>
											<CertIcon id={this.state.activeTab !== 4 ? "infoIcon" : "info"} size={30} style={this.state.activeTab === 4 ? { fill: this.state.color } : { fill: "rgb(134, 142, 150)" }} />
											<p className={this.state.activeTab === 4 ? classes.textIconActive : classes.textIcon} style={{color: this.state.activeTab === 4 ? this.state.color : "rgb(134, 142, 150)"}}>{strings.introduction_product}</p>
										</div>
									</GridItem>

									<GridItem 
									// xs={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4} 
									// sm={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4} 
									// md={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4} 
									// lg={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4}
									xs={4}
									sm={4}
									md={4}
									className={classes.centerIcon} 
									onClick={() => this.handleActive(1, 'manufacturer')}>
										<div 
										className={this.state.activeTab === 1 ? classes.cardIconActive : classes.cardIcon}  
										style={{backgroundColor: this.state.activeTab === 1 ? this.state.color + '12' : '#fff',  borderTop: this.state.activeTab === 1 ? '3px solid' + this.state.color : 'none'}}>
											<ProfileIcon 
											id={this.state.activeTab !== 1 ? "profileIcon" : "profile"} 
											size={30} 
											style={this.state.activeTab === 1 ? { fill: this.state.color } : { fill: "rgb(134, 142, 150)" }} />
											<p 
											className={this.state.activeTab === 1 ? classes.textIconActive : classes.textIcon} 
											style={{color: this.state.activeTab === 1 ? this.state.color : "rgb(134, 142, 150)"}}>{strings.origin}</p>
										</div>
									</GridItem>

									<GridItem 
									// xs={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4} 
									// sm={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4} 
									// md={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4} 
									// lg={this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? 3 : 4}
									xs={4}
									sm={4}
									md={4}
									className={classes.centerIcon} 
									onClick={() => this.handleActive(2, 'diary')}>
										<div className={this.state.activeTab === 2 ? classes.cardIconActive : classes.cardIcon} style={{backgroundColor: this.state.activeTab === 2 ? this.state.color + '12' : '#fff',  borderTop: this.state.activeTab === 2 ? '3px solid' + this.state.color : 'none'}}>
											<TreeIcon id={this.state.activeTab !== 2 ? "treeIcon" : "tree"} size={30} style={this.state.activeTab === 2 ? { fill: this.state.color } : { fill: "rgb(134, 142, 150)" }} />
											<p className={this.state.activeTab === 2 ? classes.textIconActive : classes.textIcon} style={{color: this.state.activeTab === 2 ? this.state.color : "rgb(134, 142, 150)"}}>{strings.journey}</p>
										</div>
									</GridItem>
									{/* {this.state.type !== "OBJECT" && this.state.type !== "PRODUCTTYPE" ? <GridItem xs={3} sm={3} md={3} lg={3} className={classes.centerIcon} onClick={() => this.handleActive(3, 'distributor')}>
										<div className={this.state.activeTab === 3 ? classes.cardIconActive : classes.cardIcon}  style={{ backgroundColor: this.state.activeTab === 3 ? this.state.color + '12' : '#fff',  borderTop: this.state.activeTab === 3 ? '3px solid' + this.state.color : 'none'}}>
											<TruckIcon id={this.state.activeTab !== 3 ? "carIcon" : "car"} size={30} style={this.state.activeTab === 3 ? { fill:  this.state.color } : { fill: "rgb(134, 142, 150)" }} />
											<p className={this.state.activeTab === 3 ? classes.textIconActive : classes.textIcon} style={{color: this.state.activeTab === 3 ? this.state.color : "rgb(134, 142, 150)"}}>{strings.tab_pp}</p>
										</div>
									</GridItem> : null} */}
								</GridContainer>
							</div>
						</Card>
					</div>
					<div className="contentnopad" style={{ marginTop: '-50px' }}>
						<Card>
							{this.state.info}
						</Card>
						<p>{this.state.text}</p>
					</div>
				</div>
				<div style={{ marginTop: '-46px' }}>
					<Footer />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		overview: state.overview
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
		getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
	}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LookupRice));
