import { getManufacturerList } from "actions/manufacturer";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import { truncate } from "../../../utils";
import EyeIcon from "mdi-react/EyeIcon";

const Manufacturer = (props) => {
  const getManufacturerList = props.getManufacturerList;

  React.useEffect(() => {
    getManufacturerList();
  }, []);

  const manufacturerList = props.manufacturerList;
  const [listManufacturer, setList] = React.useState([]);
  React.useEffect(() => {
    if (manufacturerList) {
      setList(manufacturerList);
    }
  }, [manufacturerList]);

  const handleSeeDetail = (id) => [
    props.history.push("/ho-kinh-doanh/" + id),
  ];
  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col md="12">
            <div className="w-100 pb-2">
              <div className="d-inline-block border-info py-1 mt-3 ">
                <span className="title-section font-weight-bold">HỘ KINH DOANH</span>
              </div>
              <a className="fl-right pr-3 mt-4 font-size-xs" href="/ho-kinh-doanh">
                Xem tất cả <ChevronRightIcon />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="mt-4 d-flex flex-wrap-content flex-item-product">
              {listManufacturer.map((item, idx) => {
                if (idx < 8) {
                  return (
                    <div
                      className="flex-stretch-card-product position-relative col-xs-4"
                      key={idx}
                    >
                      <Card className="no-transition w-100 card-blur-product card-manufacturer">
                        <a onClick={() => handleSeeDetail(item.uuid)} className="p-4 d-flex flex-column align-items-center card-container">
                          <div className="ava-product">
                            <img
                              className="img-product xy-center"
                              src={
                                item.avatar && item.avatar[0]
                                  ? item.avatar[0]
                                  : "https://res.cloudinary.com/agridential/image/upload/v1612235254/images/no_image_pbl5b1.png"
                              }
                              alt="product1"
                            />
                          </div>
                          <div className="info-product-card">
                            <p className="font-weight-bold mt-2 mb-1">
                              {truncate(item.name, 20)}
                            </p>
                            {item.description && <p className="company-short-description">
                              {truncate(item.description, 80)}
                            </p>}
                          </div>
                        </a>
                        <div className="a-size px-3">
                          <div className="text-center mobile-row">
                            <a className="btn-eye">
                              <EyeIcon
                                onClick={() => handleSeeDetail(item.uuid)}
                                className="eye-color"
                                size={20}
                              />
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                }
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  manufacturerList: state.manufacturerList.manufacturerList,
});
const mapDispatchToProps = {
  getManufacturerList,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Manufacturer)
);
