import React from "react"
import Advertisements from "../components/Advertisements"
import Footer from "../components/Footer"
import Header from "../components/Header"
import ProductList from "../components/ProductList"
import AGDProductList from '../components/AGDProductList'
import "../css/index.css"

const ProductListPage = (props) => {
    const path = props.match.path;

    return (
        <>
            <Advertisements />
            <Header />
            {path === "/san-pham-tieu-bieu-da-nang" ? <AGDProductList /> : <ProductList />}
            <Footer/>
        </>
    )
}
export default ProductListPage;