import {generateAction} from '../utils/index.js';
import {
    GET_MANUFACTURER_LIST,
    GET_MANUFACTURER_LIST_SUCCESS,
    GET_MANUFACTURER_LIST_FAILURE,
} from './types';

const getManufacturerList = generateAction(GET_MANUFACTURER_LIST);

const getManufacturerListSuccess = generateAction(GET_MANUFACTURER_LIST_SUCCESS);

const getManufacturerListFail = generateAction(GET_MANUFACTURER_LIST_FAILURE);

export {
    getManufacturerList,
    getManufacturerListSuccess,
    getManufacturerListFail,
}

export default {
    getManufacturerList,
    getManufacturerListSuccess,
    getManufacturerListFail,
}


