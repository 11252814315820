import {
    GET_CONTACT,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_FAILURE,
} from "actions/types"
const initialState = { contact: '', error: '' }
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACT:
            return {
                ...state,
            };
        case GET_CONTACT_SUCCESS:
            return {
                ...state,
                contact: action.payload
            };
        case GET_CONTACT_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state;
    }
}
export default reducer;