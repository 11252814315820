import React from "react"
import Header from "../components/Header"
import ManufacturerList from "../components/ManufacturerList"
import Footer from "../components/Footer"
import Advertisements from "../components/Advertisements"
import "../css/index.css"

const ManufacturerListPage = (props) => {
    return (
        <>
            <Advertisements />
            <Header />
            <ManufacturerList />
            <Footer/>
        </>
    )
}
export default ManufacturerListPage;