import {generateAction} from '../utils/index.js';
import {
    GET_USER_LIST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILURE,
    GET_USER_BY_ID,
    GET_USER_BY_ID_SUCCESS,
    GET_USER_BY_ID_FAILURE,
} from './types';

const getUserList = generateAction(GET_USER_LIST);

const getUserListSuccess = generateAction(GET_USER_LIST_SUCCESS);

const getUserListFail = generateAction(GET_USER_LIST_FAILURE);

const getUserByID = generateAction(GET_USER_BY_ID);

const getUserByIDSuccess = generateAction(GET_USER_BY_ID_SUCCESS);

const getUserByIDFail = generateAction(GET_USER_BY_ID_FAILURE);

export {
    getUserList,
    getUserListSuccess,
    getUserListFail,
    getUserByID,
    getUserByIDSuccess,
    getUserByIDFail,
}

export default {
    getUserList,
    getUserListSuccess,
    getUserListFail,
    getUserByID,
    getUserByIDSuccess,
    getUserByIDFail,
}